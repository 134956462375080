<template>
  <div id="app" style="position: relative;">
    <layout></layout>
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
import layout from "@/layout/index.vue";
export default {
  name: "App",
  components: {
    layout,
  },
};
</script>

<style lang="scss">
@import "./assets/common.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: rgba(248, 248, 248, 1);
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
}
</style>
