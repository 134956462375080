<template>
  <div>
    <headerIndex></headerIndex>
    <div>
      <router-view></router-view>
    </div>
    <footerIndex></footerIndex>
  </div>
</template>

<script>
import headerIndex from "@/layout/header.vue";
import footerIndex from "@/layout/footer.vue"
export default {
  name: "layoutIndex",
  components: {
    headerIndex,
    footerIndex
  },
  data() {
    return {
      input: "",
      select: "",
    };
  },
  methods: {},
  mounted() {
    console.log(this.isMobileView);
  },
};
</script>

<style lang="scss" scoped></style>
