<template>
  <div class="card-container">
    <img :src="data.img" alt="" class="img" />
    <div class="title">{{ data.title }}</div>
    <div class="count">{{ data.count }}</div>
    <div class="edit flex-between">
      <div class="left flex-center">
        <div class="cart-group flex-center">加入购物车</div>
      </div>
      <div class="right">
        <i class="el-icon-star-off" style="margin: 0 10px"></i>
        <i class="el-icon-set-up" style="margin: 0 10px"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object, // 或其他适合你数据的类型
      required: true, // 根据你的需求，这个属性是否是必须的
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-icon-star-off:hover {
  color: rgb(233, 102, 49);
}
::v-deep .el-icon-set-up:hover {
  color: rgb(233, 102, 49);
}
.card-container {
  width: 100%;
  height: auto;
  padding: 5px;
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 5px;
  background-color: #fff;
  transition: transform 0.3s ease; /* 添加动画效果 */
  overflow: hidden; /* 防止超出父容器的部分显示 */
  .img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  .title {
    padding: 8px 0 5px 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: rgba(13, 82, 214, 1);
  }
  .count {
    padding: 0 0 8px 8px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
  }
  .edit {
    width: 100%;
    height: 49px;
    padding: 8px 8px 5px;
    border-top: 1px solid rgba(238, 238, 238, 1);
    .cart-group {
      width: 72px;
      height: 20px;
      background-color: #0f3a8d;
      border-radius: 5px;
      font-size: 12px;
      color: #fff;
    }
    .cart-group:hover {
      background-color: rgba(13, 82, 214, 1);
    }
  }
}

</style>
