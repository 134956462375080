// src/utils/screenWidthWatcher.js
export default {
  data() {
    return {
      isMobileView: false, // 判断是否为移动端视图
    };
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.isMobileView = window.innerWidth < 1024;
    },
  },
};
