<template>
  <div class="content flex">
    <div class="reg">
      <span class="dia-title">商户详情</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.address"
        :rules="rules.address"
        style="padding-left: 16px"
      >
        <el-form-item label="邮箱">
          <el-input
            v-model="regForm.address.email"
            placeholder="邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" style="margin-bottom: 20px">
          <el-input
            v-model="regForm.address.phone"
            placeholder="电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户名称" prop="title">
          <el-input placeholder="商户名称" v-model="regForm.address.title">
            <template slot="prepend"><i class="el-icon-search"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item label="申请人" prop="person">
          <el-input
            v-model="regForm.address.person"
            placeholder="申请人"
          ></el-input>
        </el-form-item>
        <el-form-item label="护照ID/身份证号码" prop="ID">
          <el-input
            v-model="regForm.address.ID"
            placeholder="护照ID/身份证号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件照片（正面）" prop="ID">
          <Upload :type="1" @update-head="updateHead"></Upload>
        </el-form-item>
        <el-form-item label="证件照片（反面）" prop="ID">
          <Upload :type="2" @update-tails="updateTails"></Upload>
        </el-form-item>
        <el-form-item label="商户地址" prop="address">
          <el-input
            v-model="regForm.address.address"
            placeholder="商户地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <el-input
            v-model="regForm.address.city"
            placeholder="城市"
          ></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country">
          <el-select
            v-model="regForm.address.country"
            placeholder="请选择"
            @change="updateRegions"
          >
            <el-option
              v-for="item in countries"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省/地区" prop="region">
          <el-select v-model="regForm.address.region" placeholder="请选择">
            <el-option
              v-for="item in regions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择一个计划" prop="plan">
          <el-select v-model="regForm.address.plan" placeholder="请选择">
            <el-option
              v-for="item in plans"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="告诉我们更多关于你商户的信息">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="告诉我们更多关于你商户的信息"
            v-model="regForm.address.textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <el-button
        style="
          width: 100%;
          margin-top: 10px;

          background-color: #0f3a8d;
          color: #fff;
        "
        >创建账户</el-button
      >
    </div>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue";
export default {
  components: {
    Upload,
  },
  props: {},
  data() {
    return {
      checked: false,
      regForm: {
        address: {
          email: "",
          phone: "",
          title: "",
          person: "",
          ID: "",
          head: "",
          tails: "",
          address: "",
          city: "",
          country: "",
          region: "",
          plan: "",
          textarea:""
        },
      },
      rules: {
        address: {
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          person: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          ID: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
          address: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          city: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          country: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          region: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          plan: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
      countries: [
        { value: "US", label: "美国" },
        { value: "CN", label: "中国" },
        { value: "IN", label: "印度" },
      ],
      plans: [
        { value: "1", label: "Gold (0%) - 99999" },
        { value: "2", label: "Silver ($0.00) - 99999" },
        { value: "3", label: "Bronze (0%) - 99999" },
        { value: "4", label: "$1.00/month (USD) - 1 Year ($12.00) - 99999" },
        { value: "5", label: "$1.50/month (USD) - 6 Months ($9.00) - 999" },
      ],
      regions: [], // 动态更新的州/省/地区列表
      countryRegionMap: {
        US: [
          { value: "CA", label: "加利福尼亚" },
          { value: "NY", label: "纽约" },
          { value: "TX", label: "德克萨斯" },
        ],
        CN: [
          { value: "BJ", label: "北京" },
          { value: "SH", label: "上海" },
          { value: "GD", label: "广东" },
        ],
        IN: [
          { value: "DL", label: "德里" },
          { value: "MH", label: "马哈拉施特拉" },
          { value: "KA", label: "卡纳塔克" },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    updateRegions(country) {
      // 根据选择的国家，更新地区选项
      if (this.countryRegionMap[country]) {
        this.regions = this.countryRegionMap[country];
      } else {
        this.regions = [];
      }
      // 清空已选的地区
      this.regForm.address.region = "";
    },
    updateHead(imageUrl) {
      this.regForm.address.head = imageUrl; // 更新正面照片
      console.log(this.regForm.address.head, "更新正面照片");
    },
    updateTails(imageUrl) {
      this.regForm.address.tails = imageUrl; // 更新反面照片
      console.log(this.regForm.address.tails, "更新反面照片");
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "商家申请");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
.el-form {
  width: 70%;
}
.el-select {
  width: 100%;
}
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 100% !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  color: #333333;
  //   padding-bottom: 10px;
  //   border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  padding: 10px 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
</style>
