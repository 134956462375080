import Vue from "vue";
import Vuex from "vuex";
import VuexPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// 用来存储数据
const state = {
  person: [
    { id: 1, personName: "张三", age: 18 },
    { id: 2, personName: "张4", age: 28 },
    { id: 3, personName: "张5", age: 38 },
  ],
  breadcrumb: "",
  title: ""
};

// 响应组件中的事件
const actions = {};

// 操作数据
const mutations = {
  UPDATE_BREADCRUMB(state, title) {
    state.breadcrumb = title;
  },
  UPDATE_TITLE(state, title) {
    state.title = title;
  },
};

// 用来将state数据进行加工
const getters = {};

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  plugins: [
    VuexPersistedState({
      storage: window.localStorage, // 使用 localStorage，也可以使用 sessionStorage
      reducer(val) {
        // 将整个state数据都持久化
        return val;
      },
    }),
  ],
});
