import Vue from "vue";
import Router from "vue-router";
import Home from "@/view/home/HomeIndex.vue";
import Shop from "@/view/shop/index.vue";
import ShopDetails from "@/view/shop/ShopDetails.vue";
import Account from "@/view/account/index.vue";
import Login from "@/view/account/LoginIndex.vue";
import Forgot from "@/view/account/ForgotPassword.vue";
import Reg from "@/view/account/RegPage.vue";
import AccountIndex from "@/view/account/AccountIndex.vue";
import AddressList from "@/view/account/AddressList.vue";
import NewAddress from "@/view/account/NewAddress.vue";
import FavoritesList from "@/view/account/FavoritesList.vue";
import OrderHistory from "@/view/account/OrderHistory.vue";
import DownloadingFile from "@/view/account/DownloadingFile.vue";
import RegularPayment from "@/view/account/RegularPayment.vue";
import RewardScore from "@/view/account/RewardScore.vue";
import ReturnGoods from "@/view/account/ReturnGoods.vue";
import SubscribePage from "@/view/account/SubscribePage.vue";
import TransactionPage from "@/view/account/TransactionPage.vue";
import EditAccount from "@/view/account/EditAccount.vue";
import ChangePassword from "@/view/account/ChangePassword.vue";
import MerchantDetails from "@/view/account/MerchantDetails.vue";
import ExchangeGoods from "@/view/account/ExchangeGoods.vue";
import SessionList from "@/view/account/SessionList.vue";
import BalanceTransfer from "@/view/account/BalanceTransfer.vue";
Vue.use(Router); //Vue全局使用Router

const router = new Router({
  routes: [
    {
      path: "/", // 链接路径
      name: "home", // 路由名称
      component: Home, // 对应的组件模板
      meta: { breadcrumb: "商城" },
    },
    {
      path: "/shop", // 父路由，包含侧边栏
      name: "shop",
      component: Shop, // Shop 组件，包含侧边栏和内容区域
      children: [
        {
          path: ":id", // 根据活动 id 加载不同的 ShopDetails 内容
          name: "shopDetails",
          component: ShopDetails,
        },
      ],
    },
    {
      path: "/account", // 链接路径
      name: "account", // 路由名称
      component: Account, // 对应的组件模板
      meta: { breadcrumb: "账户" },
      children: [
        {
          path: "login", // 链接路径
          name: "login", // 路由名称
          component: Login, // 对应的组件模板
          meta: { breadcrumb: "登录" },
        },
        {
          path: "forgot", // 链接路径
          name: "forgot", // 路由名称
          component: Forgot, // 对应的组件模板
          meta: { breadcrumb: "忘记密码" },
        },
        {
          path: "reg", // 链接路径
          name: "reg", // 路由名称
          component: Reg, // 对应的组件模板
          meta: { breadcrumb: "注册" },
        },
        {
          path: "my", // 链接路径
          name: "my", // 路由名称
          component: AccountIndex, // 对应的组件模板
        },
        {
          path: "address", // 链接路径
          name: "address", // 路由名称
          component: AddressList, // 对应的组件模板
          meta: { breadcrumb: "地址簿" },
        },
        {
          path: "new", // 链接路径
          name: "new", // 路由名称
          component: NewAddress, // 对应的组件模板
          meta: { breadcrumb: "编辑地址" },
        },
        {
          path: "favorites", // 链接路径
          name: "favorites", // 路由名称
          component: FavoritesList, // 对应的组件模板
          meta: { breadcrumb: "收藏列表" },
        },
        {
          path: "history", // 链接路径
          name: "history", // 路由名称
          component: OrderHistory, // 对应的组件模板
          meta: { breadcrumb: "历史订单" },
        },
        {
          path: "downloading", // 链接路径
          name: "downloading", // 路由名称
          component: DownloadingFile, // 对应的组件模板
          meta: { breadcrumb: "下载文件" },
        },
        {
          path: "payment", // 链接路径
          name: "payment", // 路由名称
          component: RegularPayment, // 对应的组件模板
          meta: { breadcrumb: "分期付款" },
        },
        {
          path: "score", // 链接路径
          name: "score", // 路由名称
          component: RewardScore, // 对应的组件模板
          meta: { breadcrumb: "奖励积分" },
        },
        {
          path: "return", // 链接路径
          name: "return", // 路由名称
          component: ReturnGoods, // 对应的组件模板
          meta: { breadcrumb: "商品退换" },
        },
        {
          path: "subscribe", // 链接路径
          name: "subscribe", // 路由名称
          component: SubscribePage, // 对应的组件模板
          meta: { breadcrumb: "新闻订阅" },
        },
        {
          path: "transaction", // 链接路径
          name: "transaction", // 路由名称
          component: TransactionPage, // 对应的组件模板
          meta: { breadcrumb: "账户余额记录" },
        },
        {
          path: "editAccount", // 链接路径
          name: "editAccount", // 路由名称
          component: EditAccount, // 对应的组件模板
          meta: { breadcrumb: "编辑资料" },
        },
        {
          path: "changePassword", // 链接路径
          name: "changePassword", // 路由名称
          component: ChangePassword, // 对应的组件模板
          meta: { breadcrumb: "更改密码" },
        },
        {
          path: "merchant", // 链接路径
          name: "merchant", // 路由名称
          component: MerchantDetails, // 对应的组件模板
          meta: { breadcrumb: "商家申请" },
        },
        {
          path: "exchange", // 链接路径
          name: "exchange", // 路由名称
          component: ExchangeGoods, // 对应的组件模板
          meta: { breadcrumb: "商家退换" },
        },
        {
          path: "session", // 链接路径
          name: "session", // 路由名称
          component: SessionList, // 对应的组件模板
          meta: { breadcrumb: "会话列表" },
        },
        {
          path: "balance", // 链接路径
          name: "balance", // 路由名称
          component: BalanceTransfer, // 对应的组件模板
          meta: { breadcrumb: "余额转账" },
        },
      ],
    },
  ],
});

// 全局前置守卫
// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem("token"); // 从 localStorage 获取 token
//   if (to.name === "my" && !token) {
//     next({ name: "login" }); // 如果访问 /account/my 且没有 token，则跳转到 login
//   } else {
//     next(); // 正常放行
//   }
// });

export default router;
