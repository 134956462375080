<template>
  <div style="width: 100%">
    <div class="text">奖励积分总数为: 0</div>
    <el-table
      :data="tableData"
      style="width: 100%; border: 1px solid rgba(226, 226, 226, 1)"
      :header-cell-style="{ background: '#EEEEEE', color: '#000' }"
    >
      <el-table-column label="添加日期">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="描述">
        <template slot-scope="scope">
          <span>{{ scope.row.mole }}</span>
        </template>
      </el-table-column>
      <el-table-column label="积分">
        <template slot-scope="scope">
          <span>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="text" style="font-size: 13px; margin-top: 20px">
      显示 0 到 0 总计 0 (共 0 页)
    </div>
    <div class="new flex-center">继续</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tableData: [
        // {
        //  data:"",
        //  recharge:"",
        //  integral:"",
        // },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "奖励积分");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
  text-align: center;
}
::v-deep .has-gutter {
  background-color: #eeeeee;
}
::v-deep .el-button--danger {
  margin-left: 0;
  margin-top: 10px;
}
.text {
  font-size: 16px;
  color: #333333;
  margin-bottom: 15px;
}
.new {
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
  background-color: rgba(15, 58, 141, 1);
  margin-top: 20px;
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
