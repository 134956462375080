<template>
  <div class="content flex">
    <div class="reg">
      <span slot="title" class="dia-title">您的个人资料</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.order"
        :rules="rules.order"
      >
        <el-form-item label="姓氏" prop="name">
          <el-input v-model="regForm.order.name" placeholder="姓氏"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="title">
          <el-input v-model="regForm.order.title" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="E-Mail" prop="email">
          <el-input
            v-model="regForm.order.email"
            placeholder="E-Mail"
          ></el-input>
        </el-form-item>
        <el-form-item label="传真">
          <el-input
            v-model="regForm.order.data"
            placeholder="传真"
          ></el-input>
  
        </el-form-item>
      </el-form>

      <div class="flex" style="margin-top: 20px">
        <div class="back flex-center" @click="goBack">返回</div>
        <div class="new flex-center">继续</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      checked: false,
      regForm: {
        order: {
          name: "",
          title: "",
          email: "",
          phone: "",
          data: "",
        },
      },
      rules: {
        order: {
          name: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          email: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1); // 返回到上一页
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "会员账户资料");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-radio {
  margin-bottom: 10px;
}
.el-button:hover {
  background-color: #0d52d6 !important;
}
.el-form {
  width: 70%;
}
.el-select {
  width: 100%;
}
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
.text {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.back,
.new {
  flex: 1;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
}
.back {
  background-color: rgba(13, 82, 214, 1);
  margin-right: 10px;
}
.new {
  background-color: rgba(15, 58, 141, 1);
  margin-left: 10px;
}
.back:hover {
  background-color: rgba(15, 58, 141, 1);
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
