<template>
  <div class="container flex-column flex-align-center">
    <div class="container-1">
      <div class="width flex-column flex-align-center">
        <div class="title">
          <h3>按品牌购物</h3>
          <div class="line"></div>
        </div>
        <div class="img">
          <swiper :options="brandOption" ref="featruedSwiper">
            <swiper-slide
              class="flex"
              v-for="(slide, index) in swiperSlides"
              :key="index"
            >
              <div
                class="swiper-item"
                v-for="(item, itemIndex) in slide"
                :key="itemIndex"
              >
                <!-- 渲染有效项 -->
                <div v-if="item" class="swiper-item-list">
                  <img :src="item" alt="" class="img-1" />
                </div>
                <div v-if="!item" class="swiper-item empty-item"></div>
              </div>
            </swiper-slide>

            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
      </div>
    </div>
    <div class="container-2">
      <div class="width flex">
        <div
          v-for="(item, index) in brandTData"
          :key="index"
          class="swiper-item"
          
        >
          <img :src="item" alt="" class="img-1" style="border-radius: 7px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {},
  data() {
    return {
      brandOption: {
        loop: false,
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: true,
        //   disableOnInteraction: true,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 其他Swiper选项
      },
      brandData: [
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
        require("@/assets/image/homeImg/olivia-fashion.jpg"),
      ],
      brandTData: [
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
        require("@/assets/image/homeImg/4k.png"),
      ],
    };
  },
  watch: {},
  computed: {
    swiperSlides() {
      const slides = [];
      let currentSlide = [];

      this.brandData.forEach((item, index) => {
        currentSlide.push(item);
        // 如果当前组有9个元素，或者已经是最后一个元素，推送当前组到 slides 中
        if ((index + 1) % 9 === 0 || index === this.brandData.length - 1) {
          // 如果不足9个元素，则补充空的占位元素
          while (currentSlide.length < 9) {
            currentSlide.push(null); // 用 null 表示空占位符元素
          }
          slides.push(currentSlide);
          currentSlide = [];
        }
      });

      // 如果最后一组的元素不足9个，补充占位符
      if (currentSlide.length > 0 && currentSlide.length < 9) {
        while (currentSlide.length < 9) {
          currentSlide.push(null); // 空占位符
        }
        slides.push(currentSlide);
      }

      return slides;
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: auto;
}
.container-1 {
  width: 100%;
  height: auto;
  //   padding-bottom: 40px;
  padding: 50px 20px;
  background-color: #fff;
}
.container-2 {
  width: 100%;
  height: auto;
  background-color: rgba(58, 71, 84, 1);
  padding: 50px 20px 60px;
}
.title {
  font-size: 30px;
  font-weight: 700;
}
.line {
  background-color: rgba(233, 102, 49, 1);
  height: 2px;
  max-width: 50px;
  margin: 15px auto;
}
.img {
  width: 100%;
  height: auto;
}
.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  //   line-height: auto;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.swiper-pagination {
  margin-top: 10px;
  position: relative !important;
}
::v-deep .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-item {
  flex: 1;
  margin-right: 20px;
  position: relative;
  transition: transform 0.3s ease; /* 添加动画效果 */
  overflow: hidden; /* 防止超出父容器的部分显示 */
}
.swiper-item:hover .img-3 {
  transform: scale(1.2);
}
.swiper-item-list {
  width: 100%;
  height: auto;
  padding: 10px;
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 7px;
}
.img-1 {
  width: 100%;
  height: auto;
}
.empty-item {
  width: 100%; /* 保持占位符与正常元素一样宽 */
  height: 100%; /* 保持占位符与正常元素一样高 */
  // background: #f5f5f5;
}
</style>
