<template>
  <div class="container">
    <div class="width">
      <div class="page-title">
        <div class="title">{{ this.$store.state.title }}</div>
      </div>

      <div class="account">
        <div class="width flex">
          <div class="content flex">
            <router-view></router-view>
          </div>
          <div class="aside">
            <span class="dia-title">账户菜单</span>
            <el-menu
              default-active="/account/my"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              :router="true"
            >
              <el-menu-item index="/account/my">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">我的账户</span>
              </el-menu-item>
              <el-menu-item index="/account/address">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">地址簿</span>
              </el-menu-item>
              <el-menu-item index="/account/favorites">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">心愿单</span>
              </el-menu-item>
              <el-menu-item index="/account/history">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">历史订单</span>
              </el-menu-item>
              <el-menu-item index="/account/downloading">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">下载</span>
              </el-menu-item>
              <el-menu-item index="/account/payment">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">定期付款</span>
              </el-menu-item>
              <el-menu-item index="/account/score">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">奖励分数</span>
              </el-menu-item>
              <el-menu-item index="/account/return">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">退货</span>
              </el-menu-item>
               <el-menu-item index="/account/transaction">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">交易</span>
              </el-menu-item>
               <el-menu-item index="/account/subscribe">
                <i class="el-icon-arrow-right"></i>
                <span slot="title">通讯</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      formLabelAlign: {
        email: "",
        password: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}
.page-title {
  margin: 30px 0;
  font-size: 30px;
  border-bottom: 1px solid rgba(226, 226, 226, 1);
  font-weight: 600;
}
.title {
  width: 100px;
  white-space: nowrap;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(233, 102, 49, 1);
}
.account {
  width: 100%;
}
.content {
  width: 80%;
  padding: 0 30px 20px 0;
  border-right: 1px solid rgba(226, 226, 226, 1);
}
.aside {
  width: 20%;
  padding: 0 0 30px 30px;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
@media screen and (max-width: 1280px) {
  .page-title {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .content {
    padding: 0 20px 20px;
  }
  .aside {
    padding: 0 20px 20px;
  }
}
</style>
