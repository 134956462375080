<template>
  <div class="content flex">
    <div class="login">
      <div
        style="
          font-weight: 500;
          font-size: 16px;
          color: #333;
          margin-bottom: 15px;
        "
      >
        请输入您注册账户时填写的电子邮件地址，点击继续。您将收到带有密码重置链接的邮件。
      </div>
      <span class="dia-title">E-Mail 地址</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="formLabelAlign"
      >
        <el-form-item label="E-Mail 地址">
          <el-input
            v-model="formLabelAlign.email"
            placeholder="E-Mail 地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex" style="margin-top: 20px">
        <el-button
          @click="goBack"
          style="
            flex: 1;
            margin-right: 10px;
            background-color: #0f3a8d;
            color: #fff;
          "
          >返回</el-button
        >
        <el-button
          style="
            flex: 1;
            margin-left: 10px;
            background-color: #0f3a8d;
            color: #fff;
          "
          >继续</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      formLabelAlign: {
        email: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1); // 返回到上一页
    },
  },
  created() {},
  mounted() {
    this.$store.commit('UPDATE_TITLE', '忘记密码');
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
// .el-form {
//     width: 70%;
// }
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
</style>
