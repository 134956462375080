<template>
  <div class="container flex">
    <div
      class="media"
      v-if="mold === 2 || mold === 3"
      :style="{ width: width }"
    >
      <div class="bag" v-if="mold === 2">
        <video
          :src="require('@/assets/image/homeImg/video.mp4')"
          class="video"
          controls
          autoplay
        ></video>
      </div>
      <div class="bag" v-if="mold === 3" style="padding: 20px;background-color: rgba(215, 218, 222, 1);">
        <img
          :src="require('@/assets/image/homeImg/sl-1.jpg')"
          class="img-5"
          style="border-radius: 10px;"
        ></img>
      </div>
    </div>
    <div class="left">
      <div class="shopName" v-if="title">{{ title }}</div>
      <swiper :options="featruedOption" ref="featruedSwiper">
        <swiper-slide
          class="flex"
          v-for="(slide, index) in swiperSlides"
          :key="index"
        >
          <div
            class="swiper-item"
            v-for="(item, itemIndex) in slide"
            :key="itemIndex"
          >
            <!-- 渲染有效项 -->
            <div v-if="item">
              <card :data="item"></card>
            </div>
            <div v-if="!item" class="swiper-item empty-item"></div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
    <div class="right" :style="{ width: width }" v-if="mold === 1">
      <div class="shopName-rt">{{ title }}</div>
      <img
        :src="require('@/assets/image/homeImg/WomensClothing.jpg')"
        alt=""
        style="width: 100%; height: auto"
      />
      <div class="subitems">
        <div class="subitem">连衣裙</div>
        <div class="subitem">吊带背心,裙</div>
        <div class="subitem">内衣</div>
        <div class="subitem">职业女装</div>
        <div class="subitem view">View More</div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import card from "@/components/ShopCard.vue";
export default {
  components: {
    swiper,
    swiperSlide,
    card,
  },
  props: {
    title: String,
    width: String,
    mold: Number, //1为右侧图片，2为左侧视频，3为左侧轮播图，4只显示卡片
    num: {
      type: Number,
      default: 4,
    },//一行显示元素数量
  },
  data() {
    return {
      // num:4,
      featruedOption: {
        loop: false,
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: true,
        //   disableOnInteraction: true,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 其他Swiper选项
      },
      items: [
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "2024 Hot Selling Lady Winter Solid Twist Green Turtleneck Women's Sweater Knitted Pullover Clothing ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "New Designer Trolley Case With Phone Holder Water Proof Smart Luggage With Usb Charging Port Suitcase With Cup Holder ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "Wholesale Fall American Women's Clothing Crochet Lace Dress Pleated Dress Plus Size Long Sleeve White Church Evening Dresses ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "Y2k Sexy Fall Shirt Ribbed Knitting Button Long Sleeve Croptops Sexy White Crop Tops For Women ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "Y2k Sexy Fall Shirt Ribbed Knitting Button Long Sleeve Croptops Sexy White Crop Tops For Women ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "2023 New Arrivals Fashion Casual Stretchy Women Sleeveless Maxi Long Summer Dress ",
          count: "$12.43",
        },
      ],
    };
  },
  watch: {},
  computed: {
    swiperSlides() {
      const slides = [];
      let currentSlide = [];

      this.items.forEach((item, index) => {
        currentSlide.push(item);
        // 如果当前组有4个元素，或者已经是最后一个元素，推送当前组到 slides 中
        if ((index + 1) % this.num === 0 || index === this.items.length - 1) {
          // 如果不足4个元素，则补充空的占位元素
          while (currentSlide.length < this.num) {
            currentSlide.push(null); // 用 null 表示空占位符元素
          }
          slides.push(currentSlide);
          currentSlide = [];
        }
      });

      // 如果最后一组的元素不足4个，补充占位符
      if (currentSlide.length > 0 && currentSlide.length < this.num) {
        while (currentSlide.length < this.num) {
          currentSlide.push(null); // 空占位符
        }
        slides.push(currentSlide);
      }

      return slides;
    },
  },

  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  //   padding: 0 20px 20px;
  background-color: #fff;
  margin-bottom: 20px;
}
.left {
  //   width: 80%;
  flex-grow: 1;
  position: relative;
  padding: 20px 20px 0;
  overflow: hidden;
}
.right {
  //   width: 20%;
  padding: 20px;
  background: rgba(215, 218, 222, 1);
  flex-shrink: 0;
}
.media {
  padding: 20px 20px 0;
  flex-shrink: 0;
}
.video {
  width: 100%; /* 自适应宽度 */
  aspect-ratio: 1; /* 高度与宽度相等 */
  object-fit: cover; /* 确保视频完全填充容器 */
}
.img-5 {
  width: 100%;
  height: auto;
}
.subitem {
  font-size: 14px;
  color: #3a4754;
  padding-bottom: 6px;
}
.view {
  color: rgba(15, 58, 141, 1);
  font-weight: 700;
  text-decoration: underline;
}
.shopName {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 15px;
  white-space: normal;
  color: #333333;
  width: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(233, 102, 49, 1);
}
.shopName-rt {
  width: 32px;
  margin-bottom: 15px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(233, 102, 49, 1);
}
.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  //   line-height: auto;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.swiper-pagination {
  margin-top: 10px;
  position: relative !important;
}
::v-deep .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-item {
  width: 100%;
  margin-right: 20px;
  position: relative;
  transition: transform 0.3s ease; /* 添加动画效果 */
  overflow: hidden; /* 防止超出父容器的部分显示 */
}
.swiper-item:hover .img-3 {
  transform: scale(1.2);
}
.empty-item {
  width: 100%; /* 保持占位符与正常元素一样宽 */
  height: 100%; /* 保持占位符与正常元素一样高 */
  // background: #f5f5f5;
}
// .slide-item {}
.img-3 {
  width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.text-4 {
  position: absolute;
  left: 50%;
  bottom: 10px;
  right: auto;
  transform: translate(-50%, 0);
  background: rgba(240, 242, 245, 1);
  padding: 5px 10px;
  border-radius: 4px;
  color: #333333;
  margin: 10px;
  // margin-top: -10px;
  font-size: 13px;
  font-weight: 700;
  max-width: 100%;
  white-space: nowrap;
}
.btn {
  width: 100%;
  margin-top: 40px;
  .btn-content {
    font-size: 13px;
    color: #fff;
    padding: 13px 18px;
    background-color: rgba(13, 82, 214, 1);
    border-radius: 3px;
  }
  .btn-content:hover {
    background-color: #0f3a8d;
  }
}
</style>
