<template>
  <div style="width: 100%">
    <div class="text">暂无对话记录</div>
    <div class="new flex-center">继续</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "会话列表");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.text {
  font-size: 16px;
  color: #333333;
  margin-bottom: 15px;
}
.new {
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
  background-color: rgba(15, 58, 141, 1);
  margin-top: 20px;
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
