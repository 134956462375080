<template>
  <div class="content flex">
    <div class="reg" style="margin-right: 30px">
      <span class="dia-title">新会员注册</span>
      <div style="color: #333333">
        注册一个账户以便您更快捷地购物， 查看订单状态， 查看订购记录，
        和更多的管理项目。
      </div>
      <el-button
      @click="goToReg"
        style="
          width: 100%;
          margin-top: 120px;
          background-color: #0f3a8d;
          color: #fff;
        "
        >继续</el-button
      >
    </div>
    <div class="login">
      <span class="dia-title">会员登录</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="formLabelAlign"
      >
        <el-form-item label="E-Mail 地址">
          <el-input
            v-model="formLabelAlign.email"
            placeholder="E-Mail 地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="formLabelAlign.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <label class="custom-label" @click="goToForgotPassword"
          >忘记密码?</label
        >
      </el-form>
      <el-button
        class="custom-button"
        style="
          width: 100%;
          margin-top: 20px;
          background-color: #0f3a8d;
          color: #fff;
        "
        >登录</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      formLabelAlign: {
        email: "",
        password: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    goToForgotPassword() {
      this.$router.push({ name: "forgot" }); // 通过路由名称跳转到忘记密码页面
    },
    goToReg() {
      this.$router.push({ name: "reg" });  
    }
  },
  created() {},
  mounted() {
    this.$store.commit('UPDATE_TITLE', '登录账户');
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
</style>
