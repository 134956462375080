<template>
  <div class="content flex">
    <div class="reg">
      <div class="text">请完整填写下表，并获取RMA号。</div>
      <span slot="title" class="dia-title">订单信息</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.order"
        :rules="rules.order"
      >
        <el-form-item label="姓氏" prop="name">
          <el-input v-model="regForm.order.name" placeholder="姓氏"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="title">
          <el-input v-model="regForm.order.title" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="E-Mail" prop="email">
          <el-input
            v-model="regForm.order.email"
            placeholder="E-Mail"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="regForm.order.phone" placeholder="电话"></el-input>
        </el-form-item>
        <el-form-item label="订单号ID" prop="orderID">
          <el-input
            v-model="regForm.order.orderID"
            placeholder="订单号ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单日期">
          <!-- <el-input
            v-model="regForm.order.data"
            placeholder="订单日期"
          ></el-input> -->
          <el-date-picker
            v-model="regForm.order.data"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span class="dia-title">商品信息</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.Commodity"
        :rules="rules.Commodity"
      >
        <el-form-item label="商品名称" prop="title">
          <el-input
            v-model="regForm.Commodity.title"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="type">
          <el-input
            v-model="regForm.Commodity.type"
            placeholder="型号"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input
            v-model="regForm.Commodity.num"
            placeholder="数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="退换原因" prop="reasons" style="width: 20%">
          <el-radio-group v-model="regForm.Commodity.reasons">
            <el-radio :label="1"> 其他，请提供详细信息</el-radio>
            <el-radio :label="2"> 收到错误的物品</el-radio>
            <el-radio :label="3"> 有问题，请提供详细信息</el-radio>
            <el-radio :label="4"> 订单错误</el-radio>
            <el-radio :label="5"> 超过截至日期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商品包装已开" prop="isOpen">
          <el-radio-group v-model="regForm.Commodity.isOpen">
            <el-radio :label="1"> 是</el-radio>
            <el-radio :label="2"> 否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="瑕疵或其它细节" prop="text">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="瑕疵或其它细节"
            v-model="regForm.Commodity.text"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div class="flex" style="margin-top: 20px">
        <div class="back flex-center" @click="goBack">返回</div>
        <div class="new flex-center">继续</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      checked: false,
      regForm: {
        order: {
          name: "",
          title: "",
          email: "",
          phone: "",
          orderID: "",
          data: "",
        },
        Commodity: {
          title: "",
          type: "",
          num: 1,
          reasons: null,
          isOpen: 2,
          text: "",
        },
      },
      rules: {
        order: {
          name: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          email: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          orderID: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
        Commodity: {
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          type: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          reasons: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          isOpen: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1); // 返回到上一页
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "商品退换");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-radio {
  margin-bottom: 10px;
}
.el-button:hover {
  background-color: #0d52d6 !important;
}
.el-form {
  width: 70%;
}
.el-select {
  width: 100%;
}
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
.text {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.back,
.new {
  flex: 1;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
}
.back {
  background-color: rgba(13, 82, 214, 1);
  margin-right: 10px;
}
.new {
  background-color: rgba(15, 58, 141, 1);
  margin-left: 10px;
}
.back:hover {
  background-color: rgba(15, 58, 141, 1);
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
