<template>
  <div class="content flex">
    <div
      :class="['nav-list', { active: activeIndex == index }]"
      v-for="(item, index) in data"
      :key="index"
      @click="choose(index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: Array,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    choose(index) {
        this.activeIndex = index
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.content {
    margin-bottom: 20px;
}
.nav-list {
  position: relative;
  color: rgba(238, 238, 238, 1);
  padding: 11px 15px 12px;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 13px;
  color: rgba(50, 50, 50, 1);
  background-color: #eeeeee;
  border-right: 1px solid #ddd;
}
.nav-list.active,.nav-list:hover {
  background-color: #0d52d6; /* 设置点击后的蓝色背景 */
  color: #fff; /* 改变文字颜色 */
}

.nav-list.active::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #0d52d6; /* 设置三角形颜色 */
}
</style>
