<template>
  <div style="width: 100%">
    <div class="flex-between" style="margin-bottom: 15px">
      <div class="text">账户余额为: $0.00</div>
      <div class="pirmary" @click="goBlance">转账</div>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%; border: 1px solid rgba(226, 226, 226, 1)"
      :header-cell-style="{ background: '#EEEEEE', color: '#000' }"
    >
      <el-table-column label="添加日期">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="描述">
        <template slot-scope="scope">
          <span>{{ scope.row.mole }}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额 (USD)">
        <template slot-scope="scope">
          <span>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="text" style="font-size: 13px; margin-top: 20px">
      显示 0 到 0 总计 0 (共 0 页)
    </div>
    <div class="new flex-center">继续</div>

    <div class="title">余额充值</div>
    <div style="background-color: #eeeeee; padding: 10px"></div>
    <div class="title">充值方式</div>
    <div class="topUp flex">
      <div class="top-left">
        <div
          style="
            background-color: #eeeeee;
            display: flex;
            justify-content: flex-end;
          "
        >
          <img
            :src="require('@/assets/image/shopImage/visa.png')"
            alt=""
            style="width: auto; height: 40px"
          />
        </div>
      </div>
      <div class="top-right">
        <el-form
          label-position="left"
          label-width="150px"
          :model="regForm.order"
          :rules="rules.order"
        >
          <el-form-item label="金额" prop="name">
            <el-input
              v-model="regForm.order.name"
              placeholder="金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行名称" prop="title">
            <el-input
              v-model="regForm.order.title"
              placeholder="银行名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="卡号" prop="email">
            <el-input
              v-model="regForm.order.email"
              placeholder="卡号"
            ></el-input>
          </el-form-item>
          <el-form-item label="持卡人姓名" prop="phone">
            <el-input
              v-model="regForm.order.phone"
              placeholder="持卡人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="充值凭证" prop="orderID">
            <el-input
              v-model="regForm.order.orderID"
             
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="new flex-center">在线充值</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tableData: [
        // {
        //  data:"",
        //  recharge:"",
        //  integral:"",
        // },
      ],
      regForm: {
        order: {
          name: "",
          title: "",
          email: "",
          phone: "",
          orderID: "",
        },
      },
      rules: {
        order: {
          name: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          email: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          orderID: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    goBlance() {
      this.$router.push({ name: "balance" });
    }
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "账户余额");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
  text-align: center;
}
::v-deep .has-gutter {
  background-color: #eeeeee;
}
::v-deep .el-button--danger {
  margin-left: 0;
  margin-top: 10px;
}
.text {
  font-size: 16px;
  color: #333333;
}
.pirmary {
  background-color: rgba(15, 58, 141, 1);
  border-radius: 3px;
  padding: 13px 18px;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
}
.pirmary:hover {
  background-color: rgba(13, 82, 214, 1);
}
.new {
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
  background-color: rgba(15, 58, 141, 1);
  margin-top: 20px;
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
.title {
  margin: 15px 0;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}
.top-left {
  width: 33.3333%;
  margin-right: 10px;
}
.top-right {
  flex: 1;
}
</style>
