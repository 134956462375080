<template>
  <div class="container">
    <swiper :options="featruedOption" ref="featruedSwiper">
      <swiper-slide
        class="flex"
        v-for="(slide, index) in swiperSlides"
        :key="index"
      >
        <div
          class="swiper-item"
          v-for="(item, itemIndex) in slide"
          :key="itemIndex"
        >
          <!-- 渲染有效项 -->
          <div v-if="item">
            <card :data="item"></card>
          </div>
          <div v-if="!item" class="swiper-item empty-item"></div>
        </div>
      </swiper-slide>

      <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
    </swiper>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="btn flex-center">
      <div class="btn-content">
        查看所有产品
        <i class="el-icon-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import card from "@/components/ShopCard.vue";
export default {
  components: {
    swiper,
    swiperSlide,
    card,
  },
  props: {},
  data() {
    return {
      featruedOption: {
        loop: false,
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: true,
        //   disableOnInteraction: true,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 其他Swiper选项
      },
      items: [
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "2024 Hot Selling Lady Winter Solid Twist Green Turtleneck Women's Sweater Knitted Pullover Clothing ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "New Designer Trolley Case With Phone Holder Water Proof Smart Luggage With Usb Charging Port Suitcase With Cup Holder ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "Wholesale Fall American Women's Clothing Crochet Lace Dress Pleated Dress Plus Size Long Sleeve White Church Evening Dresses ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "Y2k Sexy Fall Shirt Ribbed Knitting Button Long Sleeve Croptops Sexy White Crop Tops For Women ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "Y2k Sexy Fall Shirt Ribbed Knitting Button Long Sleeve Croptops Sexy White Crop Tops For Women ",
          count: "$12.43",
        },
        {
          img: require("@/assets/image/homeImg/yifu.png"),
          title:
            "2023 New Arrivals Fashion Casual Stretchy Women Sleeveless Maxi Long Summer Dress ",
          count: "$12.43",
        },
      ],
    };
  },
  watch: {},
  computed: {
    swiperSlides() {
      const slides = [];
      let currentSlide = [];

      this.items.forEach((item, index) => {
        currentSlide.push(item);
        // 如果当前组有5个元素，或者已经是最后一个元素，推送当前组到 slides 中
        if ((index + 1) % 5 === 0 || index === this.items.length - 1) {
          // 如果不足5个元素，则补充空的占位元素
          while (currentSlide.length < 5) {
            currentSlide.push(null); // 用 null 表示空占位符元素
          }
          slides.push(currentSlide);
          currentSlide = [];
        }
      });

      // 如果最后一组的元素不足5个，补充占位符
      if (currentSlide.length > 0 && currentSlide.length < 5) {
        while (currentSlide.length < 5) {
          currentSlide.push(null); // 空占位符
        }
        slides.push(currentSlide);
      }

      return slides;
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0 20px 20px;
}
.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: auto;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.swiper-pagination {
  left: 50%;
  transform: translate(-50%);
  margin-top: 10px;
}
::v-deep .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-item {
  flex: 1;
  margin-right: 20px;
  position: relative;
  transition: transform 0.3s ease; /* 添加动画效果 */
  overflow: hidden; /* 防止超出父容器的部分显示 */
}
.swiper-item:hover .img-3 {
  transform: scale(1.2);
}
.empty-item {
  width: 100%; /* 保持占位符与正常元素一样宽 */
  height: 100%; /* 保持占位符与正常元素一样高 */
  // background: #f5f5f5;
}
// .slide-item {}
.img-3 {
  width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.text-4 {
  position: absolute;
  left: 50%;
  bottom: 10px;
  right: auto;
  transform: translate(-50%, 0);
  background: rgba(240, 242, 245, 1);
  padding: 5px 10px;
  border-radius: 4px;
  color: #333333;
  margin: 10px;
  // margin-top: -10px;
  font-size: 13px;
  font-weight: 700;
  max-width: 100%;
  white-space: nowrap;
}
.btn {
  width: 100%;
  margin-top: 40px;
  .btn-content {
     font-size: 13px;
     color: #fff;
     padding: 13px 18px;
     background-color: rgba(13, 82, 214, 1);
     border-radius: 3px;
  }
  .btn-content:hover {
    background-color: #0f3a8d;
  }
}
</style>
