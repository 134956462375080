<template>
  <div>
    <!-- 顶部 -->
    <div class="nav">
      <swiper :options="featruedOption" ref="featruedSwiper">
        <swiper-slide
          class="flex"
          v-for="(slide, index) in swiperSlides"
          :key="index"
        >
          <div
            class="swiper-item"
            v-for="(item, itemIndex) in slide"
            :key="itemIndex"
          >
            <!-- 渲染有效项 -->
            <div v-if="item" class="nav-item">
              <img :src="item.img" alt="" class="img" />
              <div class="text-1">{{ item.title }}</div>
            </div>
            <div v-if="!item" class="swiper-item empty-item"></div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
    <!-- 搜索 -->
    <div class="edit flex-between">
      <div class="edit-left">
        <i
          class="el-icon-s-grid"
          style="color: #333333; font-size: 20px; vertical-align: middle"
        ></i>
        <i
          class="el-icon-s-fold"
          style="
            color: #333333;
            font-size: 20px;
            margin-left: 5px;
            vertical-align: middle;
          "
        ></i>
        <i
          class="el-icon-set-up"
          style="
            font-size: 20px;
            color: #0d52d6;
            margin-left: 25px;
            vertical-align: middle;
          "
        ></i>
        <span style="margin-left: 5px; font-size: 16px; color: #0d52d6"
          >商品对比</span
        >
      </div>
      <div class="edit-right">
        <label class="label">排序方式</label>
        <el-select
          v-model="value"
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <label class="label">显示</label>
        <el-select v-model="numValue" placeholder="请选择">
          <el-option
            v-for="item in num"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 商品详情 -->
    <div class="main-content">
      <div class="main-list" v-for="(item, index) in 18" :key="index">
        <img
          class="img-2"
          :src="require('@/assets/image/shopImage/HTB1.jpg')"
          alt=""
        />
        <div class="name">(electronic Components) Gx1133</div>
        <div class="price">$0.10</div>
        <div class="wrapper flex-between">
          <div class="wrapper-left flex">
            <el-input-number
              v-model="wrapNum"
              controls-position="right"
              @change="handleChange"
              :min="1"
              :max="10"
            ></el-input-number>
            <div class="btn">加入购物车</div>
          </div>
          <div class="wrapper-right">
            <i
              class="el-icon-star-off"
              style="
                margin-left: 15px;
                font-size: 20px;
                color: rgba(139, 145, 152, 1);
              "
            ></i>
            <i
              class="el-icon-set-up"
              style="
                margin-left: 15px;
                font-size: 20px;
                color: rgba(139, 145, 152, 1);
                margin-right: 10px;
              "
            ></i>
          </div>
        </div>
        <div class="group flex-between">
          <div class="group-left">
            <i
              class="el-icon-question"
              style="
                font-size: 14px;
                color: rgba(80, 173, 85, 1);
                vertical-align: middle;
              "
            ></i>
            <span style="font-size: 12px; color: #323232; padding: 0 5px"
              >立即购买</span
            >
          </div>
          <div class="group-right">
            <i
              class="el-icon-question"
              style="
                font-size: 14px;
                color: rgba(221, 14, 28, 1);
                vertical-align: middle;
              "
            ></i>
            <span style="font-size: 12px; color: #323232; padding: 0 5px"
              >Question</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {},
  data() {
    return {
      value: "选项1",
      numValue: "选项1",
      wrapNum: "",
      featruedOption: {
        loop: false,
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: true,
        //   disableOnInteraction: true,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        slidesPerView: 1,
        // 其他Swiper选项
      },
      items: [
        {
          img: require("@/assets/image/shopImage/compaq_presario.jpg"),
          title: "女装",
        },
        {
          img: require("@/assets/image/shopImage/compaq_presario.jpg"),
          title: "女装",
        },
        {
          img: require("@/assets/image/shopImage/compaq_presario.jpg"),
          title: "女装",
        },
        {
          img: require("@/assets/image/shopImage/compaq_presario.jpg"),
          title: "女装",
        },
        {
          img: require("@/assets/image/shopImage/compaq_presario.jpg"),
          title: "女装",
        },
        {
          img: require("@/assets/image/shopImage/compaq_presario.jpg"),
          title: "女装",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "默认",
        },
        {
          value: "选项2",
          label: "名称 (A - Z)",
        },
        {
          value: "选项3",
          label: "名称 (Z - A)",
        },
        {
          value: "选项4",
          label: "价格 (低 > 高)",
        },
        {
          value: "选项5",
          label: "价格 (高 > 低)",
        },
        {
          value: "选项6",
          label: "最高评级",
        },
        {
          value: "选项7",
          label: "最低评级",
        },
      ],
      num: [
        {
          value: "选项1",
          label: "12",
        },
        {
          value: "选项2",
          label: "25",
        },
        {
          value: "选项3",
          label: "50",
        },
        {
          value: "选项4",
          label: "75",
        },
        {
          value: "选项5",
          label: "100",
        },
      ],
    };
  },
  watch: {},
  computed: {
    swiperSlides() {
      const slides = [];
      let currentSlide = [];

      this.items.forEach((item, index) => {
        currentSlide.push(item);
        // 如果当前组有4个元素，或者已经是最后一个元素，推送当前组到 slides 中
        if ((index + 1) % 7 === 0 || index === this.items.length - 1) {
          // 如果不足4个元素，则补充空的占位元素
          while (currentSlide.length < 7) {
            currentSlide.push(null); // 用 null 表示空占位符元素
          }
          slides.push(currentSlide);
          currentSlide = [];
        }
      });

      // 如果最后一组的元素不足4个，补充占位符
      if (currentSlide.length > 0 && currentSlide.length < 7) {
        while (currentSlide.length < 7) {
          currentSlide.push(null); // 空占位符
        }
        slides.push(currentSlide);
      }
      return slides;
    },
     activityId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  //   line-height: auto;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.swiper-pagination {
  margin-top: 10px;
  position: relative !important;
}
::v-deep .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-item {
  width: 100%;
  margin-right: 20px;
  position: relative;
  transition: transform 0.3s ease; /* 添加动画效果 */
  overflow: hidden; /* 防止超出父容器的部分显示 */
}
.swiper-item:hover .img-3 {
  transform: scale(1.2);
}
.empty-item {
  width: 100%; /* 保持占位符与正常元素一样宽 */
  height: 100%; /* 保持占位符与正常元素一样高 */
  // background: #f5f5f5;
}
.nav {
  padding: 10px 0 45px;
}
.img {
  width: 100%;
  height: auto;
}
.nav-item {
  padding: 7px;
  background-color: #fff;
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 7px;
}
.text-1 {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: rgba(50, 50, 50, 1);
}
.edit {
  width: 100%;
  height: auto;
  padding: 15px 0;
  margin-bottom: 20px;
  border-top: 1px solid rgba(226, 226, 226, 1);
  border-bottom: 1px solid rgba(226, 226, 226, 1);
}
.label {
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #eeeeee;
  color: #555555;
}

::v-deep .el-input__inner {
  height: 26px;
}
::v-deep .el-input__inner {
  width: 90px;
  box-sizing: content-box;
}
::v-deep .el-select:last-child .el-input__inner {
  width: 30px;
  //   padding: 0;
  //   padding: 0 10px !important;
  padding: 0 30px 0 15;
  box-sizing: content-box;
}
::v-deep .el-input__icon {
  line-height: 26px;
}
.main-content {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.main-list {
  width: calc(33.333% - 10px);
  height: auto;
  background-color: #fff;
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 7px;
  //   margin-bottom: 10px;
}
// .main-list:nth-child(3n + 2) {
//   margin: 0 1px;
// }
.img-2 {
  width: 100%;
  height: auto;
  border-radius: 7px;
}
.name {
  padding-left: 10px;
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  max-width: 200px;
}
.price {
  font-size: 16px;
  color: rgba(50, 50, 50, 1);
  text-align: left;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 600;
}
.wrapper {
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  padding-left: 10px;
  ::v-deep .el-input-number {
    width: 48px !important;
    height: 31px;
    span {
      width: 17px;
      height: 16px;
      border: 1px solid #dcdfe6;
      line-height: 16px;
    }
  }
  ::v-deep .el-input-number__decrease {
    bottom: -2px;
  }
  ::v-deep .el-input-number__increase {
    top: 4px;
  }

  ::v-deep .el-input .el-input__inner {
    width: 28px !important;
    padding: 0 !important;
  }
}
.btn {
  padding: 10px;
  font-size: 11px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background-color: rgba(15, 58, 141, 1);
  border-radius: 3px;
  margin-left: 5px;
}
.btn:hover {
  background-color: #0d52d6;
}
::v-deep .el-icon-star-off:hover {
  color: #0d52d6;
}
::v-deep .el-icon-set-up:hover {
  color: #0d52d6;
}
.group {
  width: 100%;
  height: auto;
  background-color: #f0f2f5;
  font-size: 16px;
  padding: 9px;
  border-radius: 0 0 7px 7px;
}
</style>
