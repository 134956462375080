<template>
  <div class="content flex">
    <div class="reg">
      <span slot="title" class="dia-title">您的个人资料</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.personal"
        :rules="rules.personal"
      >
        <el-form-item label="姓氏" prop="name">
          <el-input
            v-model="regForm.personal.name"
            placeholder="姓氏"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="title">
          <el-input
            v-model="regForm.personal.title"
            placeholder="名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="E-Mail" prop="email">
          <el-input
            v-model="regForm.personal.email"
            placeholder="E-Mail"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            v-model="regForm.personal.phone"
            placeholder="电话"
          ></el-input>
        </el-form-item>
      </el-form>
      <span class="dia-title">您的详细地址</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.address"
        :rules="rules.address"
      >
        <el-form-item label="地址" prop="address">
          <el-input
            v-model="regForm.address.address"
            placeholder="地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在城市" prop="city">
          <el-input
            v-model="regForm.address.city"
            placeholder="所在城市"
          ></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country">
          <el-select
            v-model="regForm.address.country"
            placeholder="请选择"
            @change="updateRegions"
          >
            <el-option
              v-for="item in countries"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省/地区" prop="region">
          <el-select v-model="regForm.address.region" placeholder="请选择">
            <el-option
              v-for="item in regions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span class="dia-title">账户密码</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.account"
        :rules="rules.account"
      >
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="regForm.account.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirPas">
          <el-input
            v-model="regForm.account.confirPas"
            placeholder="确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-between" style="font-size: 12px">
        <div></div>
        <el-checkbox v-model="checked"
          >我已经阅读并同意<b
            style="color: rgba(13, 82, 214, 1); text-decoration: underline"
            >Privacy Policy</b
          >
          条款</el-checkbox
        >
      </div>
      <el-button
        style="
          width: 100%;
          margin-top: 10px;
          
          background-color: #0f3a8d;
          color: #fff;
        "
        >继续</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      checked: false,
      regForm: {
        personal: {
          name: "",
          title: "",
          email: "",
          phone: "",
        },
        address: {
          address: "",
          city: "",
          country: "",
          region: "",
        },
        account: {
          password: "",
          confirPas: "",
        },
      },
      rules: {
        personal: {
          name: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          email: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
        address: {
          address: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          city: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          country: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          region: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
        account: {
          password: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          confirPas: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
      countries: [
        { value: "US", label: "美国" },
        { value: "CN", label: "中国" },
        { value: "IN", label: "印度" },
      ],
      regions: [], // 动态更新的州/省/地区列表
      countryRegionMap: {
        US: [
          { value: "CA", label: "加利福尼亚" },
          { value: "NY", label: "纽约" },
          { value: "TX", label: "德克萨斯" },
        ],
        CN: [
          { value: "BJ", label: "北京" },
          { value: "SH", label: "上海" },
          { value: "GD", label: "广东" },
        ],
        IN: [
          { value: "DL", label: "德里" },
          { value: "MH", label: "马哈拉施特拉" },
          { value: "KA", label: "卡纳塔克" },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    updateRegions(country) {
      // 根据选择的国家，更新地区选项
      if (this.countryRegionMap[country]) {
        this.regions = this.countryRegionMap[country];
      } else {
        this.regions = [];
      }
      // 清空已选的地区
      this.regForm.address.region = "";
    },
  },
  created() {},
  mounted() {
    this.$store.commit('UPDATE_TITLE', '注册');
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
.el-form {
  width: 70%;
}
.el-select {
  width: 100%;
}
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
</style>
