<template>
  <div>
    <div class="container-1">
      <div class="width page-title">
        <div class="title">{{ title }}</div>
      </div>
    </div>
    <div class="container-2">
      <div class="width flex">
        <div class="aside">
          <img
            :src="require('@/assets/image/shopImage/beauty.jpg')"
            alt=""
            class="img"
          />
          <div class="module-title">所有类别</div>
          <!-- :router="true" 开启后激活导航以index进行路由进行跳转-->
          <el-menu
            default-active="activeMenuIndex"
            class="el-menu-vertical-demo"
            active-text-color="#0D52D6"
            @open="handleOpen"
            @close="handleClose"
            :router="true"
          >
            <template v-for="(item, index) in menuItems">
              <!-- 第一层：没有子菜单的项 -->
              <el-menu-item
                v-if="!item.subItems.length"
                :key="index"
                :index="item.path"
              >
                <i class="el-icon-menu"></i>
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>

              <!-- 第一层：有子菜单的项 -->
              <el-submenu v-else :key="index" :index="item.path">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>{{ item.title }}</span>
                </template>

                <!-- 第二层：子菜单 -->
                <template v-for="(subItem, subIndex) in item.subItems">
                  <!-- 第二层的子菜单没有子菜单 -->
                  <el-menu-item
                    v-if="!subItem.subItems.length"
                    :key="subIndex"
                    :index="subItem.path"
                  >
                    {{ subItem.title }}
                  </el-menu-item>

                  <!-- 第二层的子菜单还有子菜单 -->
                  <el-submenu v-else :key="subIndex" :index="subItem.path">
                    <template slot="title">{{ subItem.title }}</template>

                    <!-- 第三层：子菜单的子菜单 -->
                    <el-menu-item
                      v-for="(subSubItem, subSubIndex) in subItem.subItems"
                      :key="subSubIndex"
                      :index="subSubItem.path"
                    >
                      {{ subSubItem.title }}
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-submenu>
            </template>
          </el-menu>
          <div class="flex-between" style="margin-bottom: 15px">
            <div class="module-title" style="margin: 0">Filter</div>
            <div class="clear">
              <i class="el-icon-error" style="color: #fff"></i>
              <span style="margin-left: 5px">Clear</span>
            </div>
          </div>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="PRICE" name="1">
              <DrogPross
                :min="0"
                :max="100"
                :value="50"
                :isDrag="true"
                bgColor="#E96631"
              ></DrogPross>
              <div class="flex-between">
                <div>
                  <span>$</span>
                  <el-input style="width: 74.5px; height: 28px !important">
                  </el-input>
                </div>
                <div>
                  <span>$</span>
                  <el-input style="width: 74.5px; height: 28px !important">
                  </el-input>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="
                Availability                "
              name="2"
            >
              <el-checkbox v-model="checked" style="margin-top: 10px"
                >In Stock</el-checkbox
              >
            </el-collapse-item>
            <el-collapse-item
              title="
                Subcategories                "
              name="3"
            >
              <div class="flex-align-center" style="padding: 5px">
                <img
                  :src="
                    require('@/assets/image/shopImage/backdrop030-38x38.jpg')
                  "
                  alt=""
                  style="width: 38px; height: 38px"
                />
                <span
                  style="
                    font-size: 16px;
                    color: #333;
                    font-weight: 400;
                    margin: 0 5px;
                  "
                  >化妆品</span
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="aside-content">
          <!-- <router-view></router-view> -->
          <ShopDetails></ShopDetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShopDetails from "@/view/shop/ShopDetails.vue";
import DrogPross from "@/components/DrogPross.vue";
export default {
  components: {
    ShopDetails,
    DrogPross,
  },
  props: {},
  data() {
    return {
      activeMenuIndex: this.$route.path,
      activeName: "1",
      checked: false,
      title: "",
      menuItems: [
        {
          title: "时尚、服装和配饰",
          path: "/shop/1",
          subItems: [
            { title: "选项1", path: "/shop/1-1", subItems: [] },
            { title: "选项2", path: "/shop/1-2", subItems: [] },
            { title: "选项3", path: "/shop/1-3", subItems: [] },
            {
              title: "选项4",
              path: "/shop/1-4",
              subItems: [{ title: "选项4-1", path: "/shop/1-4-1" }],
            },
          ],
        },
        { title: "电子产品", path: "/shop/2", subItems: [] },
        { title: "电脑", path: "/shop/3", subItems: [] },
        { title: "箱包", path: "/shop/4", subItems: [] },
        { title: "健康与美容", path: "/shop/5", subItems: [] },
        { title: "鞋履", path: "/shop/6", subItems: [] },
        { title: "食物", path: "/shop/7", subItems: [] },
        { title: "母婴", path: "/shop/8", subItems: [] },
        { title: "家装", path: "/shop/9", subItems: [] },
        { title: "厨房&卫生间", path: "/shop/10", subItems: [] },
      ],
    };
  },
  watch: {
    $route(to) {
      console.log(to.path, "路由变化");
      // 当路由变化时，更新激活的菜单项
      this.activeMenuIndex = to.path;
      this.updateCategoryTitle(to.path);
    },
  },
  computed: {},
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
      this.$router.push(key);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
      this.$router.push(key);
    },
    updateCategoryTitle(path) {
      const findTitle = (items) => {
        for (let item of items) {
          // 如果当前路径匹配，返回标题
          if (item.path === path) {
            return item.title;
          }

          // 如果有子菜单，递归查找，确保 item.subItems 存在且是数组
          if (Array.isArray(item.subItems) && item.subItems.length > 0) {
            const foundTitle = findTitle(item.subItems);
            if (foundTitle) {
              return foundTitle;
            }
          }
        }
        return ""; // 如果没有找到对应的路径
      };
      // 初始化 title
      this.title = findTitle(this.menuItems);
      this.$store.commit('UPDATE_BREADCRUMB', findTitle(this.menuItems));
      // return findTitle(this.menuItems);
    },
  },
  created() {},
  mounted() {
    console.log(this.$route.query, "名称");
    // this.title = this.$route.query.title;
    this.updateCategoryTitle(this.$route.query.path || this.$route.path);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-menu {
  background-color: rgba(248, 248, 248, 1);
  border: none;
}

::v-deep .el-collapse-item__header {
  background-color: rgba(248, 248, 248, 1) !important;
  font-weight: 600;
  color: rgba(50, 50, 50, 1);
  font-size: 16px;
}
::v-deep .el-collapse-item__content {
  background-color: rgba(248, 248, 248, 1) !important;
}
::v-deep .el-collapse-item__wrap {
  background-color: rgba(248, 248, 248, 1);
}
.container-1 {
  margin: 30px 0;
  border-bottom: 1px solid rgba(226, 226, 226, 1);
  //   padding-bottom: 10px;
}
.title {
  font-size: 30px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
  width: 100px;
  height: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(233, 102, 49, 1);
}
.aside {
  max-width: 260px;
  padding: 0 30px 30px 0;
  border-right: 1px solid rgba(226, 226, 226, 1);
  flex-shrink: 0;
}
.img {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 30px;
}
.module-title {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 15px;
  color: #333333;
  width: 50px;
  white-space: nowrap;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(233, 102, 49, 1);
}
.clear {
  background-color: rgba(15, 58, 141, 1);
  padding: 2px 6px;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;
}
.clear:hover {
  background-color: #0d52d6;
}
.aside-content {
  padding: 0 0 20px 30px;
  flex: 1;
  // width: 100%;
  overflow: hidden; /* 防止内容溢出 */
}
@media screen and (max-width: 1280px) {
  .container-1 {
    margin: 30px 20px 20px 20px;
    border-bottom: 1px solid rgba(226, 226, 226, 1);
  }
  .aside {
    max-width: 260px;
    padding: 20px;
  }
  .aside-content {
    padding: 0 20px 30px;
  }
}
</style>
