<template>
  <div>
    <div class="pc-container" v-if="!isMobileView">
      <div class="header">
        <div class="top">
          <div class="width flex-between top-content padding-10">
            <div class="top-left flex-align-center">
              <div class="home flex-center">
                <img src="../assets/image/icon/home.png" alt="" />
                <div class="text">首页</div>
              </div>
              <div class="about flex-center">
                <img src="../assets/image/icon/monitor.png" alt="" />
                <div class="text">关于我们</div>
              </div>
              <div class="contact flex-center">
                <img src="../assets/image/icon/email.png" alt="" />
                <div class="text">联系我们</div>
              </div>
            </div>
            <div class="top-right flex-center">
              <img src="../assets/image/headerImg/cn.png" alt="" class="img1" />
              <div class="text">简体中文</div>
              <i class="el-icon-caret-bottom"></i>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="width middle-content flex-align-center">
            <div class="logo padding-20 flex-align-center">
              <img src="../assets/image/headerImg/logo.png" alt="" />
            </div>
            <div class="search flex-align-center">
              <el-input
                placeholder="请输入内容"
                v-model="input"
                class="input-with-select"
              >
                <el-select v-model="select" slot="prepend" placeholder="请选择">
                  <el-option label="餐厅名" value="1"></el-option>
                  <el-option label="订单号" value="2"></el-option>
                  <el-option label="用户电话" value="3"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <div class="edit flex flex-align-center flex-align-center">
              <div class="edit-list" @click="centerDialogVisible = true">
                <img
                  src="../assets/image/headerImg/customer-center.svg"
                  alt=""
                />
                <div>登录</div>
              </div>
              <div class="edit-list" @click="centerRegDialogVisible = true">
                <img
                  src="../assets/image/headerImg/customer-center.svg"
                  alt=""
                />
                <div>注册</div>
              </div>
              <div class="edit-list">
                <img
                  src="../assets/image/headerImg/customer-center.svg"
                  alt=""
                />
                <div>收藏</div>
              </div>
              <div class="edit-list">
                <img
                  src="../assets/image/headerImg/customer-center.svg"
                  alt=""
                />
                <div>对比</div>
              </div>
            </div>
            <div class="shop flex flex-align-center padding-r-10">
              <div class="text-2">0 项商品 - $0.00</div>
              <div class="shop-icon flex-center">
                <img src="../assets/image/headerImg/cart-full.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="width bottom-content flex-align-center">
            <div class="left flex-center">
              <i class="el-icon-s-unfold" style="font-weight: 700"> 所有分类</i>
            </div>
            <div class="middle">
              <div class="info flex-center">
                <i
                  class="el-icon-chat-line-round"
                  style="font-size: 18px; margin-right: 5px"
                ></i>
                <span style="margin-right: 3px; margin-bottom: 3px">消息</span>
                <div class="mes flex-center">0</div>
              </div>
            </div>
            <div class="right flex-center">分类导航</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-contain" v-else>
      <div class="mb-header">
        <div class="mb-login flex-between">
          <div class="left flex-align-center">
            <div class="left-list flex-align-center">
              <img
                src="../assets/image/headerImg/customer-center-white.svg"
                alt=""
              />
              <div>登录</div>
            </div>
            <div class="left-list flex-align-center">
              <img
                src="../assets/image/headerImg/customer-center-white.svg"
                alt=""
              />
              <div>登录</div>
            </div>
          </div>
          <div class="right flex-center">
            <img src="../assets/image/headerImg/cn.png" alt="" />
            <div class="text">简体中文</div>
            <i class="el-icon-caret-bottom" style="color: #fff"></i>
          </div>
        </div>
        <div class="mb-search flex-between flex-align-center">
          <div class="mb-logo flex-center">
            <img src="../assets/image/headerImg/logo.png" alt="" />
          </div>

          <div class="right flex-align-center">
            <div class="right-list">
              <i class="el-icon-s-unfold" style="font-size: 30px"></i>
            </div>
            <div class="right-list">
              <i class="el-icon-search" style="font-size: 30px"></i>
            </div>
            <div class="right-list">
              <i class="el-icon-shopping-cart-full" style="font-size: 30px"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="tabBar flex-align-center">
        <div class="tabBar-list flex-center flex-column">
          <i class="el-icon-house" style="font-size: 22px"></i>
          <div class="text">首页</div>
        </div>
        <div class="tabBar-list flex-center flex-column">
          <i class="el-icon-star-off" style="font-size: 22px"></i>
          <div class="text">收藏</div>
        </div>
        <div class="tabBar-list flex-center flex-column">
          <i class="el-icon-set-up" style="font-size: 22px"></i>
          <div class="text">对比</div>
        </div>
        <div class="tabBar-list flex-center flex-column">
          <i class="el-icon-message" style="font-size: 22px"></i>
          <div class="text">邮箱</div>
        </div>
        <div class="tabBar-list flex-center flex-column">
          <i class="el-icon-phone-outline" style="font-size: 22px"></i>
          <div class="text">联系我们</div>
        </div>
      </div>
    </div>
    <div class="breadcrumb" v-if="$route.path != '/'">
      <div class="width">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"
            ><i class="el-icon-house" style="font-size: 14px"></i
          ></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: breadcrumbText.path }">{{
            this.$store.state.breadcrumb || breadcrumbText.breadcrumb
          }}</el-breadcrumb-item>
        </el-breadcrumb> -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <!-- 遍历面包屑 -->
          <el-breadcrumb-item
            v-for="(item, index) in breadcrumbText()"
            :key="index"
            :to="{ path: item.path }"
          >
            <!-- 如果是首页，显示图标 -->
            <template v-if="index === 0">
              <i :class="item.icon" style="font-size: 14px"></i>
            </template>
            <span>{{ item.breadcrumb }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <!-- 登录页面 -->
    <el-dialog :visible.sync="centerDialogVisible" width="500px" center>
      <span slot="title" class="dia-title">会员登录</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="formLabelAlign"
      >
        <el-form-item label="E-Mail 地址">
          <el-input
            v-model="formLabelAlign.email"
            placeholder="E-Mail 地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="formLabelAlign.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <label class="custom-label" @click="goToForgotPassword"
          >忘记密码?</label
        >
      </el-form>
      <el-button
        type="primary"
        style="
          width: 100%;
          margin-top: 20px;
          background-color: #0f3a8d;
          color: #fff;
        "
        >登录</el-button
      >
      <!-- <span slot="footer" class="dialog-footer"> </span> -->
    </el-dialog>
    <!-- 注册页面 -->
    <el-dialog
      :visible.sync="centerRegDialogVisible"
      width="500px"
      center
      class="reg"
    >
      <span slot="title" class="dia-title">您的个人资料</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.personal"
        :rules="rules.personal"
      >
        <el-form-item label="姓氏" prop="name">
          <el-input
            v-model="regForm.personal.name"
            placeholder="姓氏"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="title">
          <el-input
            v-model="regForm.personal.title"
            placeholder="名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="E-Mail" prop="email">
          <el-input
            v-model="regForm.personal.email"
            placeholder="E-Mail"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            v-model="regForm.personal.phone"
            placeholder="电话"
          ></el-input>
        </el-form-item>
      </el-form>
      <span class="dia-title">您的详细地址</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.address"
        :rules="rules.address"
      >
        <el-form-item label="地址" prop="address">
          <el-input
            v-model="regForm.address.address"
            placeholder="地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在城市" prop="city">
          <el-input
            v-model="regForm.address.city"
            placeholder="所在城市"
          ></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country">
          <el-select
            v-model="regForm.address.country"
            placeholder="请选择"
            @change="updateRegions"
          >
            <el-option
              v-for="item in countries"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省/地区" prop="region">
          <el-select v-model="regForm.address.region" placeholder="请选择">
            <el-option
              v-for="item in regions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span class="dia-title">账户密码</span>
      <el-form
        label-position="left"
        label-width="150px"
        :model="regForm.account"
        :rules="rules.account"
      >
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="regForm.account.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirPas">
          <el-input
            v-model="regForm.account.confirPas"
            placeholder="确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-between" style="font-size: 12px">
        <div></div>
        <el-checkbox v-model="checked"
          >我已经阅读并同意<b
            style="color: rgba(13, 82, 214, 1); text-decoration: underline"
            >Privacy Policy</b
          >
          条款</el-checkbox
        >
      </div>
      <el-button
        type="primary"
        style="
          width: 100%;
          margin-top: 20px;
          background-color: #0f3a8d;
          color: #fff;
        "
        >继续</el-button
      >
      <!-- <span slot="footer" class="dialog-footer"> </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "headerIndex",
  data() {
    return {
      input: "",
      select: "",
      centerDialogVisible: false,
      centerRegDialogVisible: false,
      checked: false,
      formLabelAlign: {
        email: "",
        password: "",
      },
      regForm: {
        personal: {
          name: "",
          title: "",
          email: "",
          phone: "",
        },
        address: {
          address: "",
          city: "",
          country: "",
          region: "",
        },
        account: {
          password: "",
          confirPas: "",
        },
      },
      rules: {
        personal: {
          name: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          email: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
        address: {
          address: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          city: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          country: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          region: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
        account: {
          password: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          confirPas: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
      countries: [
        { value: "US", label: "美国" },
        { value: "CN", label: "中国" },
        { value: "IN", label: "印度" },
      ],
      regions: [], // 动态更新的州/省/地区列表
      countryRegionMap: {
        US: [
          { value: "CA", label: "加利福尼亚" },
          { value: "NY", label: "纽约" },
          { value: "TX", label: "德克萨斯" },
        ],
        CN: [
          { value: "BJ", label: "北京" },
          { value: "SH", label: "上海" },
          { value: "GD", label: "广东" },
        ],
        IN: [
          { value: "DL", label: "德里" },
          { value: "MH", label: "马哈拉施特拉" },
          { value: "KA", label: "卡纳塔克" },
        ],
      },
    };
  },
  computed: {
    // breadcrumbText() {
    //   // 获取当前路由的meta信息
    //   const meta = this.$route.meta;
    //   const path = this.$route.path;
    //   // 检查是否有定义breadcrumb属性
    //   return {
    //     breadcrumb: meta && meta.breadcrumb ? meta.breadcrumb : "",
    //     path: path,
    //   };
    // },
  },
  methods: {
    breadcrumbText() {
      let pathArray = []; // 用于存放面包屑路径的数组
      const visitedRoutes = new Set(); // 用于记录已访问过的路由，避免重复

      // 先将首页加入 pathArray
      pathArray.push({
        breadcrumb: "首页",
        path: "/",
        icon: "el-icon-house", // 首页图标
      });

      // 遍历 this.$route.matched 获取所有匹配的路由记录
      this.$route.matched.forEach((route) => {
        if (
          route.meta &&
          route.meta.breadcrumb &&
          !visitedRoutes.has(route.path)
        ) {
          pathArray.push({
            breadcrumb: route.meta.breadcrumb,
            path: route.path,
          });
          visitedRoutes.add(route.path);
        }
      });

      // 如果 Vuex 中有 breadcrumb 数据，且不重复，则追加到面包屑末尾
      const breadcrumbFromStore = this.$store.state.breadcrumb;
      if (
        breadcrumbFromStore &&
        !pathArray.some((item) => item.breadcrumb === breadcrumbFromStore)
      ) {
        pathArray.push({
          breadcrumb: breadcrumbFromStore,
          path: this.$route.path,
        });
      }

      return pathArray;
    },
    updateRegions(country) {
      // 根据选择的国家，更新地区选项
      if (this.countryRegionMap[country]) {
        this.regions = this.countryRegionMap[country];
      } else {
        this.regions = [];
      }
      // 清空已选的地区
      this.regForm.address.region = "";
    },
    goToForgotPassword() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "forgot" }); // 通过路由名称跳转到忘记密码页面
    },
  },
  mounted() {
    console.log(this.isMobileView);
    console.log(this.$route.meta, this.$route.path, "路由");
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  text-align: left;
}
.el-select {
  width: 98px;
  background-color: #0d52d6;
  color: #fff;
}
.reg {
  .el-select {
    width: 100%;
    background-color: transparent;
  }
}

.el-button {
  padding: 12px 12px;
  background-color: #0d52d6;
}

.header {
  .top {
    width: 100%;
    height: 35px;
    background-color: #fff;
    font-size: 13px;
    color: #323232;

    .top-content {
      height: 100%;

      img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }

      .img1 {
        width: 16px;
        height: 11px;
        vertical-align: bottom;
        margin-top: 2px;
        margin-right: 7px;
      }

      .top-left {
        div {
          margin-right: 5px;
        }
      }
    }
  }

  .middle {
    width: 100%;
    height: 100px;

    .middle-content {
      height: 100%;

      .logo {
        width: 230px;

        img {
          width: 172px;
          height: auto;
        }
      }

      .search {
        flex: 1;
        height: 40px;
        margin: 0 25px;
      }

      .edit {
        width: 156px;

        // height: 100%;
        .edit-list {
          width: 25%;
          font-size: 11px;
          color: #323232;
          height: 40px;
          text-align: center;
        }
      }

      .shop {
        width: 239px;
        justify-content: flex-end;

        .text-2 {
          font-weight: 700;
          font-size: 17px;
          color: rgba(23, 29, 34, 1);
          padding: 0 15px;
        }

        .shop-icon {
          width: 40px;
          height: 40px;
          background-color: #0d52d6;
          border-radius: 3px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 43px;
    background-color: #0d52d6;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;

    .bottom-content {
      height: 100%;

      .left {
        height: 100%;
        width: 230px;
        background-color: #fed430;
        font-size: 13px;
        color: #333333;
        font-weight: 700;
      }

      .middle {
        flex: 1;
        height: 100%;

        .info {
          width: 102px;
          height: 100%;
          color: #fff;
          font-size: 13px;

          .mes {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #dd0e1c;
            border: 2px solid #fff;
            font-size: 11px;
            margin-right: 5px;
            margin-bottom: 3px;
          }
        }
      }

      .right {
        height: 100%;
        width: 82px;
        background-color: #fed430;
        font-size: 13px;
        color: #333333;
        font-weight: 700;
      }
    }
  }
}

.mb-header {
  width: 100%;

  .mb-login {
    width: 100%;
    height: 40px;
    background-color: #0d52d6;
    padding: 0 7px;

    .left {
      font-size: 12px;
      color: #fff;

      .left-list {
        width: 53px;

        img {
          width: 18px;
          height: 18px;
          color: #fff;
          margin-right: 5px;
        }
      }
    }

    .right {
      font-size: 11px;
      color: #fff;

      img {
        margin-top: 3px;
        margin-right: 5px;
      }
    }
  }

  .mb-search {
    width: 100%;
    height: 60px;

    .mb-logo {
      padding: 15px;

      img {
        width: 114px;
        height: auto;
      }
    }

    .right-list {
      height: 100%;
      width: 50px;
    }

    .righr-list:last-child {
      width: 65px;
    }
  }
}

.tabBar {
  width: 100%;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;

  .tabBar-list {
    width: 20%;
    height: 100%;
    border-right: 1px solid #ccc;

    // border-top: 1px solid #ccc;
    .text {
      font-size: 10px;
      color: rgba(139, 145, 152, 1);
    }
  }

  .tabBar-list:last-child {
    border-right: none;
  }
}
.breadcrumb {
  width: 100%;
  padding: 10px;
}
::v-deep .el-dialog {
  margin-top: 0 !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 620px;
  overflow-y: auto;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
.reg {
  ::v-deep .el-form-item {
    margin-bottom: 18px;
  }
}
@media screen and (max-width: 1280px) {
  .breadcrumb {
    padding: 10px 10px 10px 15px;
  }
}
</style>
