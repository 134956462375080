<template>
  <div>
    <div class="container-1">
      <div class="width">
        <div class="text-1">最近查看的</div>
        <div class="tab-content flex">
          <div class="tab-content-list flex">
            <div class="left">
              <img
                :src="require('@/assets/image/homeImg/bag.jpg')"
                alt=""
                class="img-1"
              />
            </div>
            <div class="right">
              <div class="name">
                Hanke Luxury Aluminum Frame Spinner Trolley Suitcase Business
                Travel Luggage Set Multi-functional Suitcase Luggage
              </div>
              <div class="price">$53.99</div>
              <div class="icon flex-align-center" style="height: 30px">
                <i
                  class="el-icon-shopping-cart-full"
                  style="margin: 0 10px"
                ></i>
                <i class="el-icon-star-off" style="margin: 0 10px"></i>
                <i class="el-icon-set-up" style="margin: 0 10px"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-2">
      <div class="width flex">
        <div class="about-list">
          <h3 class="title">关于我们</h3>
          <div class="about-item">关于我们</div>
          <div class="about-item">隐私政策</div>
          <div class="about-item">条款和条件</div>
          <div class="about-item"></div>
        </div>
        <div class="about-list">
          <h3 class="title">客户服务</h3>
          <div class="about-item">联系</div>
          <div class="about-item">产品退货</div>
          <div class="about-item">站点地图</div>
          <div class="about-item">品牌</div>
        </div>
        <div class="about-list">
          <h3 class="title">我的账号</h3>
          <div class="about-item">我的账号</div>
          <div class="about-item">订单历史</div>
          <div class="about-item">通讯</div>
          <div class="about-item">礼券</div>
        </div>
        <div class="about-list" style="border: none">
          <h3 class="title">新闻稿</h3>
          <div class="newsletter-text">
            注册我们的时事通讯，了解最新的新闻和促销活动。
          </div>
          <el-input v-model="input2" style="margin-bottom: 20px">
            <template slot="append">
              <div>
                <i class="el-icon-message" style="margin-right: 10px"></i>发送
              </div>
            </template>
          </el-input>
          <el-radio v-model="radio" label="1"
            >我已阅读并同意<b style="color: #0d52d6; text-decoration: underline"
              >Privacy Policy</b
            >条款</el-radio
          >
        </div>
      </div>
    </div>
    <div class="container-3">
      <div class="width">
        <div class="top flex-between">
          <div class="title">版权所有</div>
          <div class="title" style="direction: rtl">图标菜单</div>
        </div>
        <div class="bottom flex-between">
          <div class="copyright">
            Copyright © 2024, LAZADA Wholesale, All Rights Reserved
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      input2: "",
      radio: "",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-icon-shopping-cart-full {
  font-size: 16px;
  color: rgba(50, 50, 50, 1);
}
::v-deep .el-icon-star-off {
  font-size: 16px;
  color: rgba(50, 50, 50, 1);
}
::v-deep .el-icon-set-up {
  font-size: 16px;
  color: rgba(50, 50, 50, 1);
}
::v-deep .el-icon-shopping-cart-full:hover {
  color: rgb(233, 102, 49);
}
::v-deep .el-icon-star-off:hover {
  color: rgb(233, 102, 49);
}
::v-deep .el-icon-set-up:hover {
  color: rgb(233, 102, 49);
}
::v-deep .el-input-group__append {
  background-color: #0f3a8d;
  color: #fff;
  padding: 0 10px;
}
::v-deep .el-input-group__append:hover {
  background-color: #0d52d6;
}
::v-deep .el-radio__label {
  color: rgba(139, 145, 152, 1);
  font-size: 12px;
}
.container-1 {
  padding: 20px;
  background-color: rgba(58, 71, 84, 1);
}
.container-2 {
  padding: 20px 0;
}
.container-3 {
  padding: 20px 20px 15px;
  background-color: rgba(58, 71, 84, 1);
}
.text-1 {
  width: 80px;
  color: rgba(230, 230, 230, 1);
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(233, 102, 49, 1);
}
.tab-content {
  padding-top: 20px;
}
.tab-content-list {
  padding: 7px;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 3px;
  width: 25%;
}
.left {
  width: 25%;
}
.right {
  width: calc(100% - 25%);
  padding-left: 8px;
}
.img-1 {
  width: 100%;
  height: auto;
}
.name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 13px;
  color: rgba(51, 51, 51, 1);
}
.price {
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 5px;
}
.about-list {
  width: 25%;
  border-right: 1px solid rgba(226, 226, 226, 1);
  padding: 0 20px 20px;
}
.title {
  width: 50px;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 15px;
  white-space: nowrap;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(233, 102, 49, 1);
}
.about-item {
  height: 36px;
  color: rgba(13, 82, 214, 1);
  font-size: 16px;
}
.about-item:hover {
  color: rgba(233, 102, 49, 1);
}
.newsletter-text {
  color: rgba(50, 50, 50, 1);
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}
.copyright {
  color: #8B9198;
  font-size: 16px;
  font-weight: 700;
}
</style>
