<template>
  <div style="width: 100%">
    <el-table
      :data="tableData"
      style="width: 100%; border: 1px solid rgba(226, 226, 226, 1)"
      :header-cell-style="{ background: '#EEEEEE', color: '#000' }"
    >
      <el-table-column label="图像" width="100">
        <template slot-scope="scope">
          <el-image
            style="width: 100%; height: 75px"
            :src="scope.row.img"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品名称">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.mole }}</span>
        </template>
      </el-table-column>
      <el-table-column label="库存" width="100">
        <template slot-scope="scope">
          <span :style="{ color: scope.row.inventory ? 'green' : 'red' }">
            {{ scope.row.inventory ? "有现货" : "暂无" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="单位价格" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="new flex-center">继续</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tableData: [
        {
          img: "",
          title:
            "Camisole Satin Robes Dcontractes Plush Dress Autumn Women's Fashion Wear Vestidos Sexy Backless Slit Long Skirt",
          mole: "1",
          inventory: true,
          price: "$8.50",
        },
        {
          img: "",
          title:
            "Camisole Satin Robes Dcontractes Plush Dress Autumn Women's Fashion Wear Vestidos Sexy Backless Slit Long Skirt",
          mole: "1",
          inventory: true,
          price: "$8.50",
        },
        {
          img: "",
          title:
            "Camisole Satin Robes Dcontractes Plush Dress Autumn Women's Fashion Wear Vestidos Sexy Backless Slit Long Skirt",
          mole: "1",
          inventory: true,
          price: "$8.50",
        },
        {
          img: "",
          title:
            "Camisole Satin Robes Dcontractes Plush Dress Autumn Women's Fashion Wear Vestidos Sexy Backless Slit Long Skirt",
          mole: "1",
          inventory: true,
          price: "$8.50",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "收藏列表");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
  text-align: center;
}
::v-deep .has-gutter {
  background-color: #eeeeee;
}
::v-deep .el-button--danger {
  margin-left: 0;
  margin-top: 10px;
}
.new {
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
  background-color: rgba(15, 58, 141, 1);
  margin-top: 20px;
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
