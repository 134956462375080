<template>
  <div class="content flex">
    <div class="reg">
      <el-form
        label-position="right"
        label-width="100px"
        :model="regForm.address"
        :rules="rules.address"
      >
        <el-form-item label="姓氏" prop="name">
          <el-input
            v-model="regForm.address.name"
            placeholder="姓氏"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="title">
          <el-input
            v-model="regForm.address.title"
            placeholder="名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司">
          <el-input
            v-model="regForm.address.company"
            placeholder="公司"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址1" prop="address">
          <el-input
            v-model="regForm.address.address1"
            placeholder="地址1"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址2">
          <el-input
            v-model="regForm.address.address2"
            placeholder="地址2"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在城市" prop="city">
          <el-input
            v-model="regForm.address.city"
            placeholder="所在城市"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮政编码" prop="city">
          <el-input
            v-model="regForm.address.code"
            placeholder="邮政编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="国家" prop="country">
          <el-select
            v-model="regForm.address.country"
            placeholder="请选择"
            @change="updateRegions"
          >
            <el-option
              v-for="item in countries"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="州/省/地区" prop="region">
          <el-select v-model="regForm.address.region" placeholder="请选择">
            <el-option
              v-for="item in regions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认地址">
          <el-radio v-model="regForm.address.radio" label="1">是</el-radio>
          <el-radio v-model="regForm.address.radio" label="2">否</el-radio>
        </el-form-item>
      </el-form>
      <div class="flex" style="margin-top: 20px">
        <div class="back flex-center" @click="goBack">返回</div>
        <div class="new flex-center">继续</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      checked: false,
      regForm: {
        address: {
          name: "",
          title: "",
          company: "",
          address1: "",
          address2: "",
          code: "",
          city: "",
          country: "",
          region: "",
          radio: '1'
        },
      },
      rules: {
        address: {
          title: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          name: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          address: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          city: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          country: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
          region: [
            { required: true, message: "请输入活动名称", trigger: "blur" },
          ],
        },
      },
      countries: [
        { value: "US", label: "美国" },
        { value: "CN", label: "中国" },
        { value: "IN", label: "印度" },
      ],
      regions: [], // 动态更新的州/省/地区列表
      countryRegionMap: {
        US: [
          { value: "CA", label: "加利福尼亚" },
          { value: "NY", label: "纽约" },
          { value: "TX", label: "德克萨斯" },
        ],
        CN: [
          { value: "BJ", label: "北京" },
          { value: "SH", label: "上海" },
          { value: "GD", label: "广东" },
        ],
        IN: [
          { value: "DL", label: "德里" },
          { value: "MH", label: "马哈拉施特拉" },
          { value: "KA", label: "卡纳塔克" },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    updateRegions(country) {
      // 根据选择的国家，更新地区选项
      if (this.countryRegionMap[country]) {
        this.regions = this.countryRegionMap[country];
      } else {
        this.regions = [];
      }
      // 清空已选的地区
      this.regForm.address.region = "";
    },
    goBack() {
      this.$router.go(-1); // 返回到上一页
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "编辑地址");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
.el-form {
  width: 70%;
}
.el-select {
  width: 100%;
}
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.reg,
.login {
  flex: 1;
}
.dia-title {
  display: inline-block;
  width: 50px !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
  // margin-bottom: 15px;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e96631;
  margin-bottom: 15px;
}
.custom-label {
  font-weight: 500;
  font-size: 16px;
  color: rgba(13, 82, 214, 1);
  text-decoration: underline;
}
.back,
.new {
  flex: 1;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
}
.back {
  background-color: rgba(13, 82, 214, 1);
  margin-right: 10px;
}
.new {
  background-color: rgba(15, 58, 141, 1);
  margin-left: 10px;
}
.back:hover {
  background-color: rgba(15, 58, 141, 1);
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
