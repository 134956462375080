<template>
  <div class="content">
    <div class="flex-between address-content">
      <div class="left">
        mi young
        <br />
        Seoul
        <br />
        Seoul
        <br />
        Cholla-bukto
        <br />
        South Korea
      </div>
      <div class="right flex">
        <div class="btn btn-1" @click="goNew">编辑</div>
        <div class="btn btn-2">删除</div>
      </div>
    </div>
    <div class="flex" style="margin-top: 20px">
      <div class="back flex-center" @click="goBack">返回</div>
      <div class="new flex-center" @click="goNew">新地址</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1); // 返回到上一页
    },
    goNew() {
      this.$router.push({ name: "new" });
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "地址列表");
    this.$store.commit("UPDATE_BREADCRUMB", "地址簿");
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
// .el-form {
//     width: 70%;
// }
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.address-content {
  width: 100%;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.left {
  font-size: 16px;
  color: #323232;
  font-weight: 400;
}
.btn {
  font-size: 13px;
  color: #fff;
  padding: 13px 18px;
  font-weight: 400;
  border-radius: 3px;
}
.btn-1 {
  background-color: #323232;
  margin-right: 10px;
}
.btn-2 {
  background-color: rgba(221, 14, 28, 1);
}
.btn:hover {
  background-color: rgba(13, 82, 214, 1);
}
.back,
.new {
  flex: 1;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
}
.back {
  background-color: rgba(13, 82, 214, 1);
  margin-right: 10px;
}
.new {
  background-color: rgba(15, 58, 141, 1);
  margin-left: 10px;
}
.back:hover {
  background-color: rgba(15, 58, 141, 1);
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
