import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import screenWidthWatcher from "./utils/screenWidthWatcher";
import "swiper/dist/css/swiper.css";
//（如果使用的是2.6.0以上的版本需要自己手动去加载css）
import VueAwesomeSwiper from "vue-awesome-swiper";

import store from "./store/index";


Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Vue.mixin(screenWidthWatcher);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
