<template>
  <div>
    <div class="width">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo flex-center"
        mode="horizontal"
        @select="handleSelect"
        background-color="rgba(248, 248, 248, 1)"
        text-color="#0D52D6"
        active-text-color="#e46430"
        :router="true"
        ref="menuContainer"
      >
        <div class="flex-align-center text-1">
          <span class="dot"></span> 快速链接
        </div>
        <el-menu-item
          :index="item.path"
          :route={path:item.path,query:{title:item.title,path:item.path,}}
          v-for="(item, index) in menuItem"
          :key="index"
          class="flex-align-center menu-item-with-dot"
          ><span class="dot"></span>{{ item.title }}</el-menu-item
        >
      </el-menu>
    </div>

    <!-- 轮播图 -->
    <div class="diagram">
      <div class="width flex-align-center diagram-content">
        <el-carousel class="car-width">
          <el-carousel-item v-for="(item, index) in diaData" :key="index">
            <img class="img-1" :src="item" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="dia-rt">
          <div class="list" style="margin-bottom: 10px">
            <img
              class="img-2"
              :src="require('@/assets/image/homeImg/watch.png')"
              alt=""
            />
            <div class="banner-text">
              <span><s>Wearables</s> Intelligent & Durable Design</span>
            </div>
          </div>
          <div class="list" style="margin-top: 10px; padding: 10px">
            <img
              class="img-2"
              :src="require('@/assets/image/homeImg/pc.png')"
              alt=""
            />
            <div class="banner-text">
              <span><s>Computers</s> Build your own high powered PC</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- navbar -->
    <div class="navbar">
      <div class="width flex-align-center nav-content">
        <div class="navbar-list flex-center">
          <div class="icon flex-center">
            <i
              class="el-icon-truck"
              style="color: rgba(233, 102, 49, 1); font-size: 30px"
            ></i>
          </div>
          <div style="margin-left: 15px">
            <div
              style="
                font-size: 15px;
                font-weight: 600;
                color: rgba(230, 230, 230, 1);
              "
            >
              免费送货
            </div>
            <div
              style="
                font-size: 14px;
                color: rgba(139, 145, 152, 1);
                font-weight: 400;
              "
            >
              超过100美元的免费送货
            </div>
          </div>
        </div>
        <div class="navbar-list flex-center">
          <div class="icon flex-center">
            <i
              class="el-icon-top-left"
              style="color: rgba(233, 102, 49, 1); font-size: 30px"
            ></i>
          </div>
          <div style="margin-left: 15px">
            <div
              style="
                font-size: 15px;
                font-weight: 600;
                color: rgba(230, 230, 230, 1);
              "
            >
              免费退货
            </div>
            <div
              style="
                font-size: 14px;
                color: rgba(139, 145, 152, 1);
                font-weight: 400;
              "
            >
              无忧退货
            </div>
          </div>
        </div>
        <div class="navbar-list flex-center">
          <div class="icon flex-center">
            <i
              class="el-icon-present"
              style="color: rgba(233, 102, 49, 1); font-size: 30px"
            ></i>
          </div>
          <div style="margin-left: 15px">
            <div
              style="
                font-size: 15px;
                font-weight: 600;
                color: rgba(230, 230, 230, 1);
              "
            >
              安全购物
            </div>
            <div
              style="
                font-size: 14px;
                color: rgba(139, 145, 152, 1);
                font-weight: 400;
              "
            >
              最佳安全功能
            </div>
          </div>
        </div>
        <div class="navbar-list flex-center">
          <div class="icon flex-center">
            <i
              class="el-icon-box"
              style="color: rgba(233, 102, 49, 1); font-size: 30px"
            ></i>
          </div>
          <div style="margin-left: 15px">
            <div
              style="
                font-size: 15px;
                font-weight: 600;
                color: rgba(230, 230, 230, 1);
              "
            >
              24小时客户服务
            </div>
            <div
              style="
                font-size: 14px;
                color: rgba(139, 145, 152, 1);
                font-weight: 400;
              "
            >
              请拨打我们的客服电话。
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 热销分类 -->
    <div class="hot">
      <div class="width flex-center flex-column">
        <NavBar :data="hotData"></NavBar>
        <div class="recommendPage">
          <div class="width recommendPage-content">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide
                class="flex"
                v-for="(slide, index) in swiperSlides"
                :key="index"
              >
                <div
                  class="swiper-item"
                  v-for="(item, itemIndex) in slide"
                  :key="itemIndex"
                >
                  <!-- 渲染有效项 -->
                  <div v-if="item">
                    <img class="img-3" :src="item.img" alt="" />
                    <div class="text-4">{{ item.title }}</div>
                  </div>
                  <!-- 空占位项，仍然是一个swiper-item -->
                  <div v-if="!item" class="swiper-item empty-item"></div>
                  <!-- 空占位元素 -->
                  <!-- 空占位项 -->
                </div>
              </swiper-slide>

              <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 特色商品 -->
    <div class="features">
      <div class="width flex-center flex-column">
        <NavBar :data="featuresData"></NavBar>
        <FeaturedGoods></FeaturedGoods>
      </div>
    </div>

    <!-- 销售商品 -->
    <div class="sales">
      <div class="width flex-center flex-column">
        <SalesShop :title="'女装'" :width="'20%'" :mold="1"></SalesShop>
        <SalesShop :title="'男装'" :width="'20%'" :mold="1"></SalesShop>
        <SalesShop :title="'童装'" :width="'20%'" :mold="1"></SalesShop>
        <SalesShop :title="'箱包'" :width="'30%'" :mold="2"></SalesShop>
        <SalesShop :title="'箱包'" :width="'30%'" :mold="2"></SalesShop>
        <SalesShop :title="''" :width="'30%'" :mold="4"></SalesShop>
      </div>
    </div>

    <!-- 品牌 -->
    <BrandShop></BrandShop>

    <!-- 销售商品 -->
    <div class="sales">
      <div class="width flex-center flex-column">
        <SalesShop :title="''" :width="'50%'" :mold="3"></SalesShop>
        <SalesShop :title="''" :width="'50%'" :mold="3" :num="3"></SalesShop>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/view/home/NavBar.vue";
import FeaturedGoods from "@/view/home/FeaturedGoods.vue";
import SalesShop from "@/view/home/SalesShop.vue";
import BrandShop from "@/view/home/BrandShop.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "HomeIndex",
  components: {
    NavBar,
    FeaturedGoods,
    SalesShop,
    BrandShop,
    swiper,
    swiperSlide,
  },
  props: {},
  data() {
    return {
      activeIndex: "1",
      items: [
        {
          img: require("@/assets/image/homeImg/ElectricAppliances.jpg"),
          title: "家用电器",
        },
        {
          img: require("@/assets/image/homeImg/ElectricAppliances.jpg"),
          title: "电脑、办公用品",
        },
        {
          img: require("@/assets/image/homeImg/ElectricAppliances.jpg"),
          title: "手机、数码产品",
        },
        {
          img: require("@/assets/image/homeImg/ElectricAppliances.jpg"),
          title: "时尚、服装和配饰",
        },
        {
          img: require("@/assets/image/homeImg/ElectricAppliances.jpg"),
          title: "行李箱包",
        },
        {
          img: require("@/assets/image/homeImg/ElectricAppliances.jpg"),
          title: "行李箱包",
        },
      ],
      swiperOption: {
        loop: false,
        autoplay: false,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: true,
        //   disableOnInteraction: true,
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 其他Swiper选项
      },
      menuItem: [
        { title: "时尚", path: "/shop/1" },
        { title: "电子产品", path: "/shop/2" },
        { title: "电脑", path: "/shop/3" },
        { title: "箱包", path: "/shop/4" },
        { title: "健康与美容", path: "/shop/5" },
        { title: "鞋履", path: "/shop/6" },
        { title: "食物", path: "/shop/7" },
        { title: "母婴", path: "/shop/8" },
        { title: "家装", path: "/shop/9" },
        { title: "厨房&卫生间", path: "/shop/10" },
      ],
      diaData: [
        require("@/assets/image/homeImg/item1.jpg"),
        require("@/assets/image/homeImg/item2.jpg"),
        require("@/assets/image/homeImg/item3.png"),
      ],
      hotData: ["热销分类", "家用电器", "健康与美容", "时尚", "全部", "风格"],
      featuresData: ["特色商品", "最新商品", "畅销商品", "特价商品"],
    };
  },
  watch: {},
  computed: {
    swiperSlides() {
      const slides = [];
      let currentSlide = [];

      this.items.forEach((item, index) => {
        currentSlide.push(item);
        // 如果当前组有5个元素，或者已经是最后一个元素，推送当前组到 slides 中
        if ((index + 1) % 5 === 0 || index === this.items.length - 1) {
          // 如果不足5个元素，则补充空的占位元素
          while (currentSlide.length < 5) {
            currentSlide.push(null); // 用 null 表示空占位符元素
          }
          slides.push(currentSlide);
          currentSlide = [];
        }
      });

      // 如果最后一组的元素不足5个，补充占位符
      if (currentSlide.length > 0 && currentSlide.length < 5) {
        while (currentSlide.length < 5) {
          currentSlide.push(null); // 空占位符
        }
        slides.push(currentSlide);
      }

      return slides;
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.recommendPage {
  padding-top: 20px;
  width: 100%;
}
.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: auto;
}
.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  line-height: auto;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.swiper-pagination {
  left: 50%;
  transform: translate(-50%);
  margin-top: 10px;
}
::v-deep .swiper-pagination-bullet {
  margin: 5px;
}
.swiper-item {
  flex: 1;
  margin-right: 20px;
  position: relative;
  transition: transform 0.3s ease; /* 添加动画效果 */
  overflow: hidden; /* 防止超出父容器的部分显示 */
}
.swiper-item:hover .img-3 {
  transform: scale(1.2);
}
.empty-item {
  width: 100%; /* 保持占位符与正常元素一样宽 */
  height: 100%; /* 保持占位符与正常元素一样高 */
  // background: #f5f5f5;
}
.img-3 {
  width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease;
}
// .img-3:hover {
//   transform: scale(1.5);
// }
.text-4 {
  position: absolute;
  left: 50%;
  bottom: 10px;
  right: auto;
  transform: translate(-50%, 0);
  background: rgba(240, 242, 245, 1);
  padding: 5px 10px;
  border-radius: 4px;
  color: #333333;
  margin: 10px;
  // margin-top: -10px;
  font-size: 13px;
  font-weight: 700;
  max-width: 100%;
  white-space: nowrap;
}

.el-menu-item:hover {
  color: #e46430 !important; /* 鼠标悬停时的文本颜色 */
  background-color: rgba(248, 248, 248, 1) !important;
}
.el-menu-item {
  border: none !important;
  padding: 0 !important;
  height: 48px !important;
  font-size: 16px;
}
.el-menu {
  border: none;
  height: 48px !important;
  font-size: 16px;
}
.text-1 {
  font-weight: 700;
}
::v-deep .el-carousel__container {
  height: 440px;
}
::v-deep .el-carousel__item {
  height: auto;
}
.car-width {
  width: 75%;
  padding-right: 20px;
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(58, 71, 84, 1);
  margin: 0 7px;
}
.img-1 {
  width: 100%;
  height: auto;
  aspect-ratio: auto 940 / 440;
  border-radius: 10px;
}
.diagram {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  .diagram-content {
    .car-width {
      // border-radius: 10px;
      height: 100%;
    }
  }
}
.dia-rt {
  width: 25%;
  height: auto;
  .list {
    position: relative;
    background-color: rgba(248, 248, 248, 1);
    border-radius: 10px;
    height: auto;
    .img-2 {
      width: 100%;
      height: auto;
      aspect-ratio: auto 320 / 210;
    }
    .banner-text {
      position: absolute;
      top: 30%;
      left: 0;
      right: auto;
      bottom: auto;
      span {
        display: block;
        max-width: 38%;
        white-space: normal;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-left: 25px;
        s {
          display: block;
          max-width: 20%;
          color: rgba(13, 82, 214, 1);
          text-transform: uppercase;
          margin-bottom: 5px;
          text-decoration: none;
          font-size: 70%;
          font-style: normal;
          font-weight: 400;
          white-space: normal;
          line-height: 1;
          max-width: 125px;
        }
      }
    }
  }
}
.navbar {
  width: 100%;
  height: 110px;
  background-color: #3a4754;
  .nav-content {
    height: 100%;
    .navbar-list {
      width: 25%;
      height: 100%;
      .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid rgba(13, 82, 214, 1);
      }
    }
  }
}

.hot {
  width: 100%;
  padding: 30px 20px 40px;
  height: auto;
  background-color: #fff;
}
.features {
  width: 100%;
  padding: 40px 20px;
  height: auto;
  background: #f8f8f8;
}
.sales {
  width: 100%;
  padding: 50px 15px 60px;
  height: auto;
  background-color: #f0f2f5;
}

@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1024px) {
  .el-menu {
    display: flex;
    align-items: center;
    justify-content: start;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    height: 48px;
  }
  .text-1 {
    width: 83px !important;
    height: 48px;
    white-space: nowrap;
    text-align: center;
    padding-bottom: 2px;
  }
}
</style>
