<template>
  <div style="width: 100%">
    <div class="flex-align-center">
      <div
        class="text flex-align-center"
        style="height: 100%; margin-right: 20px"
      >
        订阅
      </div>
      <el-radio v-model="radio" label="1">是</el-radio>
      <el-radio v-model="radio" label="2">否</el-radio>
    </div>
    <div class="flex" style="margin-top: 20px">
      <div class="back flex-center" @click="goBack">返回</div>
      <div class="new flex-center">继续</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      radio: "2",
    };
  },
  watch: {},
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1); // 返回到上一页
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "订阅本站最新动态");
    this.$store.commit("UPDATE_BREADCRUMB", this.$route.meta.breadcrumb);
  },
};
</script>
<style lang="scss" scoped>
.text {
  font-size: 14px;
  color: #333333;
  //   margin-bottom: 15px;
  padding-bottom: 3px;
}
.back,
.new {
  flex: 1;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  padding: 13px 0;
  border-radius: 3px;
}
.back {
  background-color: rgba(13, 82, 214, 1);
  margin-right: 10px;
}
.new {
  background-color: rgba(15, 58, 141, 1);
  margin-left: 10px;
}
.back:hover {
  background-color: rgba(15, 58, 141, 1);
}
.new:hover {
  background-color: rgba(13, 82, 214, 1);
}
</style>
