<template>
  <div class="content">
    <div class="flex">
      <div class="list flex-column flex-center" @click="goEditAccount">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-edit" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">编辑用户资料</div>
      </div>
      <div class="list flex-column flex-center" @click="goChangePassword">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-edit" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">修改密码</div>
      </div>
      <div class="list flex-column flex-center" @click="goNewAddress">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i
            class="el-icon-notebook-2"
            style="color: #000; font-size: 24px"
          ></i>
        </div>
        <div class="text">修改地址簿</div>
      </div>
      <div class="list flex-column flex-center" @click="goFavorites">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-star-off" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">修改收藏列表</div>
      </div>
      <div class="list flex-column flex-center" @click="goMerchant">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i
            class="el-icon-arrow-right"
            style="color: #000; font-size: 24px"
          ></i>
        </div>
        <div class="text">商户中心</div>
      </div>
    </div>
    <div class="title">历史订单</div>
    <div class="flex">
      <div class="list flex-column flex-center" @click="goOrder">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-tickets" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">查看历史订单</div>
      </div>
      <div class="list flex-column flex-center" @click="goScore">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-medal" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">奖励积分</div>
      </div>
      <div class="list flex-column flex-center" @click="goExchange">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-edit" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">查看我的退换要求</div>
      </div>
      <div class="list flex-column flex-center" @click="goTransaction">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-edit" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">账户余额</div>
      </div>
      <div class="list" style="border: none"></div>
    </div>
    <div class="title">新闻订阅</div>
    <div class="flex">
      <div class="list flex-column flex-center" @click="goSubscribe">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-message" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">订阅 / 退订新闻邮件</div>
      </div>
      <div class="list flex-column flex-center" @click="goSession">
        <div class="flex-center" style="width: 45px; height: 58.5px">
          <i class="el-icon-message" style="color: #000; font-size: 24px"></i>
        </div>
        <div class="text">站内信</div>
      </div>
      <div class="list" style="border: none"></div>
      <div class="list" style="border: none"></div>
      <div class="list" style="border: none"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    goEditAccount() {
      this.$router.push({ name: "editAccount" });
    },
    goChangePassword() {
      this.$router.push({ name: "changePassword" });
    },
    goNewAddress() {
      this.$router.push({ name: "new" });
    },
    goFavorites() {
      this.$router.push({ name: "favorites" });
    },
    goMerchant() {
      this.$router.push({ name: "merchant" });
    },
    goOrder() {
      this.$router.push({ name: "history" });
    },
    goScore() {
      this.$router.push({ name: "score" });
    },
    goExchange() {
      this.$router.push({ name: "exchange" });
    },
    goTransaction() {
      this.$router.push({ name: "transaction" });
    },
    goSubscribe() {
      this.$router.push({ name: "subscribe" });
    },
    goSession() {
      this.$router.push({ name: "session" });
    },
  },
  created() {},
  mounted() {
    this.$store.commit("UPDATE_TITLE", "会员账户");
    this.$store.commit("UPDATE_BREADCRUMB", "");
  },
};
</script>
<style lang="scss" scoped>
.el-button:hover {
  background-color: #0d52d6 !important;
}
// .el-form {
//     width: 70%;
// }
.content {
  width: 100%;
  padding: 0;
  border: none;
}
.list {
  width: 20%;
  border-radius: 3px;
  border: 1px solid rgba(226, 226, 226, 1);
  padding: 10px 0;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-left: 10px;
}
.list:first-child {
  margin: 0;
}
.list:hover {
  background-color: #fff; /* hover 时背景色变为白色 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}
.text {
  font-size: 13px;
  color: rgba(50, 50, 50, 1);
  font-weight: 500;
}
.title {
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  margin: 15px 0;
}
</style>
